import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../app/utilities/helpers";

const cookies = new Cookies();
export const getApplicationList = createAsyncThunk(
  "get/getApplicationList",
  async ({ userName, userRole }) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/cmdb/${userName}/${userRole}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          }
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getProjectList = createAsyncThunk(
  "get/getProjectList",
  async (payload) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/home/projectLists`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          }
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getSprintList = createAsyncThunk(
  "get/getSprintList",
  async (payload) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/home/sprint/sprintNames`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          }
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const observabilitySlice = createSlice({
  name: "observability",
  initialState: {
    filterData: {
      selectedApplications: [],
      selectedProjects: [],
      selectedSprints: [],
      selectedDate: {},
      isFilterClicked: false,
      selectedAccountId: [],
      filterDataLoading: false,
      applicationList: [],
      projectList: [],
      sprintList: []
    },
    panelState: {},
    panelLoading: false,

  },
  reducers: {
    setFilterData: {
      reducer(state, action) {
        state.filterData = action.payload;
      },
      prepare(filterData, changedData) {

        return { payload: { ...filterData, ...changedData } };
      },
    },
    updateFilterDataFromHome: {
      reducer(state, action) {
        // state.filterData.selectedApplications = action.payload
      },
      prepare(filterData, changedData) {
        return { payload: { ...filterData, ...changedData } };
      },
    },
    //fix for devops metrics date issue, need to analyze why date issue occured in the devops filter alone
    setFilterDataDate: {
      reducer(state, action) {
        state.filterData.selectedDate = action.payload;
      },
    },
    resetFilterData: {
      reducer(state, action) {
        state.filterData = {
          selectedApplications: [],
          selectedProjects: [],
          selectedSprints: [],
          selectedDate: {},
          isFilterClicked: false,
          selectedAccountId: [],
        }
      }
    },
    setPanelVisibility: {
      reducer(state, action) {
        state.panelState[`is${action.payload}Open`] = !get(
          state,
          `panelState.is${action.payload}Open`,
          false
        );
      },
    },
    resetFilterData: {
      reducer(state, action) {
        state.filterData = {
          selectedApplications: [],
          selectedProjects: [],
          selectedSprints: [],
          selectedDate: {},
          isFilterClicked: false,
          selectedAccountId: [],
        }
      }
    },
    resetSelectedApp: {
      reducer(state, action) {
        state.filterData.selectedApplications = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationList.pending, (state, action) => {
        state.filterDataLoading = true;
      })
      .addCase(getApplicationList.fulfilled, (state, action) => {
        state.filterDataLoading = false;
        state.applicationList = action.payload;
      })
      .addCase(getApplicationList.rejected, (state, action) => {
        state.filterDataLoading = false;
        state.applicationList = []
      })
      //project list
      .addCase(getProjectList.pending, (state, action) => {
        state.filterDataLoading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action) => {
        state.filterDataLoading = false;
        state.projectList = action.payload;
      })
      .addCase(getProjectList.rejected, (state, action) => {
        state.filterDataLoading = false;
        state.projectList = []
      })
      //sprint list
      .addCase(getSprintList.pending, (state, action) => {
        state.filterDataLoading = true;
      })
      .addCase(getSprintList.fulfilled, (state, action) => {
        state.filterDataLoading = false;
        state.sprintList = action.payload;
      })
      .addCase(getSprintList.rejected, (state, action) => {
        state.filterDataLoading = false;
        state.sprintList = []
      })
  }
});

export const { updateFilterDataFromHome, resetFilterData, setFilterData, setFilterDataDate, setPanelVisibility, resetSelectedApp } = observabilitySlice.actions;

export default observabilitySlice.reducer;
