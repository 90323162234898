import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../../../app/utilities/helpers';

const cookies = new Cookies();

export const emailConfigurationList = createAsyncThunk(
    "post/emailConfigurationList",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`/api/v1/schedulerCompliance/list/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            )
            handleResponseCode(response);
            const data = await response.json();

            return data;
        } catch (error) {
            throw error;
        }
    }
)


export const emailConfigurationListCount = createAsyncThunk(
    "post/emailConfigurationListCount",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`/api/v1/schedulerCompliance/listCountWithSearch`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return data;
        } catch (error) {
            throw error;
        }
    }
)
export const emailConfigurationDelete = createAsyncThunk("DELETE/emailConfigurationDelete",
    async (props) => {
        try {
            const { id, userName } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(`/api/v1/schedulerCompliance/delete/${id}/${userName}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                },
            }
            );
            handleResponseCode(response);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    });
export const emailConfigurationSaveAndUpdate = createAsyncThunk("post/emailConfigurationSaveAndUpdate",
    async (props) => {
        try {
            const { savePayload, userName } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(`/api/v1/schedulerCompliance/${userName}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                },
                body: JSON.stringify(savePayload)
            });
            handleResponseCode(response);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    });
export const emailConfigurationModuleList = createAsyncThunk("get/emailConfigurationModuleList", async () => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(`/api/v1/complianceDashboard/dashboardName`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
        },
    }).then((resp) => resp.json());
    handleResponseCode(res);
    return res;
});

const initialState = {
    tableLoading: false,
    listTableData: [],
    tableCountLoading: false,
    listTableDataCount: [],
    listDelete: '',
    listDeleteLoading: false,
    saveAndUpdating: '',
    saveAndUpdatingLoading: false,
    tableReload: false,
    configurationModuleList: [],
    configurationModuleListLoading: false,
};
export const EmailScheduleConfigSlice = createSlice({
    name: 'EmailScheduleConfigSlice',
    initialState: initialState,
    reducers: {
        resetEmailScheduleConfigData: (state) => {
            state.saveAndUpdating = '';
            state.listDelete = '';
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(emailConfigurationList.pending, (state, action) => {
                state.tableLoading = true;
            })
            .addCase(emailConfigurationList.fulfilled, (state, action) => {
                state.listTableData = action.payload;
                state.tableLoading = false;
                state.tableReload = false
            })
            .addCase(emailConfigurationList.rejected, (state, action) => {
                state.tableLoading = false;
            })


            .addCase(emailConfigurationListCount.pending, (state, action) => {
                state.tableCountLoading = true;
            })
            .addCase(emailConfigurationListCount.fulfilled, (state, action) => {
                state.listTableDataCount = action.payload;
                state.tableCountLoading = false;
            })
            .addCase(emailConfigurationListCount.rejected, (state, action) => {
                state.tableCountLoading = false;
            })

            .addCase(emailConfigurationDelete.pending, (state, action) => {
                state.listDeleteLoading = true;
            })
            .addCase(emailConfigurationDelete.fulfilled, (state, action) => {
                state.listDelete = action.payload;
                state.listDeleteLoading = false;
                state.tableReload = true
            })
            .addCase(emailConfigurationDelete.rejected, (state, action) => {
                state.listDeleteLoading = false;
            })

            .addCase(emailConfigurationSaveAndUpdate.pending, (state, action) => {
                state.saveAndUpdatingLoading = true;
            })
            .addCase(emailConfigurationSaveAndUpdate.fulfilled, (state, action) => {
                state.saveAndUpdating = action.payload;
                state.saveAndUpdatingLoading = false;
                state.tableReload = true
            })
            .addCase(emailConfigurationSaveAndUpdate.rejected, (state, action) => {
                state.saveAndUpdatingLoading = false;
            })

            .addCase(emailConfigurationModuleList.pending, (state, action) => {
                state.configurationModuleListLoading = true;
            })
            .addCase(emailConfigurationModuleList.fulfilled, (state, action) => {
                state.configurationModuleList = action.payload;
                state.configurationModuleListLoading = false;
            })
            .addCase(emailConfigurationModuleList.rejected, (state, action) => {
                state.configurationModuleListLoading = false;
            })

    },

});
export const { resetEmailScheduleConfigData } = EmailScheduleConfigSlice.actions;
export default EmailScheduleConfigSlice.reducer;