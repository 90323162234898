import { createSlice } from "@reduxjs/toolkit";

const getAppCode = createSlice({
    name: 'AppCode',
    initialState: {
        getApp: '',
        getModule:0,
        getTableData:'',
        homeSelectedApp: {},
        filterData: {
          selectedApplications: [],
          selectedProjects: [],
          selectedSprints: [],
          selectedDate:{},
          isFilterClicked:false,
          selectedAccountId:[],
        }
    },
    reducers: {
      setAppCode: (state, action) => {
        state.getApp = action.payload;
      },
      setModule:(state, action) => {
        state.getModule = action.payload;
      },
      setTableData:(state, action) => {
        state.getTableData = action.payload;
      },
      updateSelectedApp: (state, action) => {
        console.log('update selected app');
        state.homeSelectedApp = action.payload
      },
      resetHomeData: (state, action) => {
        let initialState =  {
          getApp: '',
          // getModule:state.getModule,
          getModule:0,
          getTableData:'',
          homeSelectedApp: {},
          filterData: {
            selectedApplications: [],
            selectedProjects: [],
            selectedSprints: [],
            selectedDate:{},
            isFilterClicked:false,
            selectedAccountId:[],
          }

      }
      return initialState;
      },
      updateFilterDataFromHome: {
        reducer(state, action) {
          // state.filterData.selectedApplications = action.payload
        },
        prepare(filterData, changedData) {
          return { payload: { ...filterData, ...changedData } };
        },
      },
      setFilterData: {
        reducer(state, action) {
          state.filterData = action.payload;
        },
        prepare(filterData, changedData) {
  
          return { payload: { ...filterData, ...changedData } };
        },
      },
      setFilterDataDate: {
        reducer(state, action) {
          state.filterData.selectedDate = action.payload;
        },
      },
      resetFilterData: {
        reducer(state, action) {
          state.filterData = {
            selectedApplications: [],
          selectedProjects: [],
          selectedSprints: [],
          selectedDate:{},
          isFilterClicked:false,
          selectedAccountId:[],
          }
        }
      },
      resetSelectedApp:{
        reducer(state, action){
          state.filterData.selectedApplications=[];
        }
      }
    }
  });
  
  export const { updateSelectedApp, setAppCode,setModule,setTableData, resetHomeData, setFilterData, updateFilterDataFromHome, setFilterDataDate, resetFilterData, resetSelectedApp } = getAppCode.actions;
  export default getAppCode.reducer;