/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";

const cookies = new Cookies();

export const securityHubList = createAsyncThunk(
  "post/securityHubList",
  async (props) => {
    const { pageNumber, itemsPerPage, payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `/api/v1/aws/getFixedSecurityHubListBySearchWithStatus/list/${pageNumber}/${itemsPerPage}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        const result = res.json();
        return result;
      })
      .catch((err) => {
        return err;
      });
    handleResponseCode(res);
    return res;
  }
);
export const securityHubCurrentList = createAsyncThunk(
  "post/securityHubCurrentList",
  async (props) => {
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const res = await fetch(
        `/api/v1/aws/getSecurityHubListBySearchWithStatus/list/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(res);
      const data = res.json();
      return res.ok ? data : Promise.reject(data);
    } catch (error) {
      console.log('Error', error);
      throw (error)
    }
  });
export const SecurityHubCurrentListCount = createAsyncThunk(
  "post/securityHubCurrentListCount",
  async (props) => {
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const res = await fetch(
        `/api/v1/aws/getSecurityHubListBySearchBasedOnCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(res);
      const data = res.json();
      return res.ok ? data : Promise.reject(data);
    } catch (error) {
      console.log("Error", error);
      throw (error);
    }
  }
);
export const SecurityHubDrilldownList = createAsyncThunk(
  "post/SecurityHubDrilldownList",
  async (props) => {
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const res = await fetch(
        `/api/v1/aws/getSecurityHubListBySearchWithStatus/list/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(res);
      const data = res.json();
      return res.ok ? data : Promise.reject(data);
    } catch (error) {
      console.log('Error', error);
      throw (error)
    }
  });
export const SecurityHubDrilldownListCount = createAsyncThunk(
  "post/SecurityHubDrilldownListCount",
  async (props) => {
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const res = await fetch(
        `/api/v1/aws/getSecurityHubListBySearchBasedOnCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(res);
      const data = res.json();
      return res.ok ? data : Promise.reject(data);
    } catch (error) {
      console.log("Error", error);
      throw (error);
    }
  }
);
export const securityHubCount = createAsyncThunk(
  "post/securityHubCount",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `/api/v1/aws/getFixedSecurityHubListBySearchBasedOnCount`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        const result = res.json();
        return result;
      })
      .catch((err) => {
        return err;
      });
    handleResponseCode(res);
    return res;
  }
);
export const securityHubNonComplianceByaccountIdAPI = createAsyncThunk(
  "post/securityHubNonComplianceByaccountId",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `/api/v1/aws/getFailedCount`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        const result = res.json();
        return result;
      })
      .catch((err) => {
        return err;
      });
    handleResponseCode(res);
    return res;
  }
);
export const securityHubNonComplianceByTypeAPI = createAsyncThunk(
  "post/securityHubNonComplianceByType",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `/api/v1/aws/getNonCompliantByResourceWiseCount`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        const result = res.json();
        return result;
      })
      .catch((err) => {
        return err;
      });
    handleResponseCode(res);
    return res;
  }
);
export const getSHFTargetMissedItems = createAsyncThunk(
  "post/getSHFTargetMissedItems",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`/api/v1/aws/getTargetDates`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getSHFTargetDueTrend = createAsyncThunk(
  "post/getSHFTargetDueTrend",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`/api/v1/aws/getSixMonthTargetDate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getSHFOpenFindings = createAsyncThunk(
  "post/getOpenFindingsCount",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`/api/v1/aws/getOpenFindingsCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getSHFClosedFindings = createAsyncThunk(
  "post/getClosedFindingsCount",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`/api/v1/aws/getClosedFindingsCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const SHFFindingsTrend = createAsyncThunk(
  "post/SHFFindingsTrend",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`/api/v1/aws/complianceGraph/list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const NonComplianceAppCri = createAsyncThunk(
  "post/NonComplianceAppCri",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`/api/v1/aws/getNonCompliantCountByCriticality`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const NonComplianceBySeverity = createAsyncThunk(
  "post/NonComplianceBySeverity",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`/api/v1/aws/getSeverityCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const FindingsDistribution = createAsyncThunk(
  "post/FindingsDistribution",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(
        `/api/v1/aws/findingsDistribution`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
  securityHubListFixed: [],
  securityHubCurrentList: [],
  securityHubCurrentCount: [],
  securityHubDrilldownList: [],
  securityHubDrilldownCount: [],
  securityHubCount: [],
  tableLoading: false,
  CurentShTableLoading: false,
  DrilldowntableLoading: false,
  DrilldownShTableLoading: false,
  error: '',
  nonComplianceById: [],
  nonComplianceByIdLoading: false,
  nonComplianceByType: [],
  nonComplianceByTypeLoading: false,
  securityHubTargetMissedItems: [],
  securityHubTargetMissedItemsLoading: false,
  securityHubTargetDueTrend: [],
  securityHubTargetDueTrendLoading: false,
  securityHubOpenFindings: [],
  securityHubOpenFindingsLoading: false,
  securityHubClosedFindings: [],
  securityHubClosedFindingsLoading: false,
  DistributionChart: [],
  FindingDistributionloading: false,
  SHFFindingsTrendData:[],
  SHFFindingsTrendLoading:false,
  NonComplianceAppCriData:[],
  NonComplianceAppCriLoading:false,
  NonComplianceBySeverityLoading:false,
  NonComplianceBySeverityData:[],
}
export const SecurityHubSlice = createSlice({
  name: "shfSlice",
  initialState,
  reducers: {
    resetSecurityHubData: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(securityHubList.pending, (state, action) => {
        state.tableLoading = true;
        state.error = "";
      })
      .addCase(securityHubList.fulfilled, (state, action) => {
        state.tableLoading = false;
        state.securityHubListFixed = action.payload;
      })
      .addCase(securityHubList.rejected, (state, action) => {
        state.tableLoading = false;
        state.securityHubListFixed = [];
        state.error = "something went wrong"
      })
      .addCase(securityHubCount.pending, (state, action) => {
        state.tableLoading = true;
        state.error = "";
      })
      .addCase(securityHubCount.fulfilled, (state, action) => {
        state.tableLoading = false;
        state.securityHubCount = action.payload;
      })
      .addCase(securityHubCount.rejected, (state, action) => {
        state.tableLoading = false;
      })
      .addCase(securityHubCurrentList.pending, (state, action) => {
        state.CurentShTableLoading = true;
        state.error = "";
      })
      .addCase(securityHubCurrentList.fulfilled, (state, action) => {
        state.CurentShTableLoading = false;
        state.securityHubCurrentList = action.payload;
      })
      .addCase(securityHubCurrentList.rejected, (state, action) => {
        state.CurentShTableLoading = false;
        state.securityHubCurrentList = [];
        state.error = "Something went wrong"
      })

      .addCase(SecurityHubCurrentListCount.pending, (state, action) => {
        state.CurentShTableLoading = true;
        state.error = "";
      })
      .addCase(SecurityHubCurrentListCount.fulfilled, (state, action) => {
        state.CurentShTableLoading = false;
        state.securityHubCurrentCount = action.payload;
      })
      .addCase(SecurityHubCurrentListCount.rejected, (state, action) => {
        state.CurentShTableLoading = false;
        state.securityHubCurrentCount = [];
        state.error = "Something went wrong"
      })
      .addCase(SecurityHubDrilldownList.pending, (state, action) => {
        state.DrilldowntableLoading = true;
        state.error = "";
      })
      .addCase(SecurityHubDrilldownList.fulfilled, (state, action) => {
        state.DrilldowntableLoading = false;
        state.securityHubDrilldownList = action.payload;
      })
      .addCase(SecurityHubDrilldownList.rejected, (state, action) => {
        state.DrilldowntableLoading = false;
        state.securityHubDrilldownList = [];
        state.error = "Something went wrong"
      })

      .addCase(SecurityHubDrilldownListCount.pending, (state, action) => {
        state.DrilldownShTableLoading = true;
        state.error = "";
      })
      .addCase(SecurityHubDrilldownListCount.fulfilled, (state, action) => {
        state.DrilldownShTableLoading = false;
        state.securityHubDrilldownCount = action.payload;
      })
      .addCase(SecurityHubDrilldownListCount.rejected, (state, action) => {
        state.DrilldownShTableLoading = false;
        state.securityHubDrilldownCount = [];
        state.error = "Something went wrong"
      })

      .addCase(securityHubNonComplianceByaccountIdAPI.pending, (state, action) => {
        state.nonComplianceByIdLoading = true;
      })
      .addCase(securityHubNonComplianceByaccountIdAPI.fulfilled, (state, action) => {
        if (action.payload.status === 500) {
          state.nonComplianceById = [];
          state.nonComplianceByIdLoading = false;
        } else {
          state.nonComplianceByIdLoading = false;
          state.nonComplianceById = action.payload;
        }
      })

      .addCase(securityHubNonComplianceByaccountIdAPI.rejected, (state, action) => {
        state.nonComplianceByIdLoading = false;
        state.nonComplianceById = [];
      })

      .addCase(securityHubNonComplianceByTypeAPI.pending, (state, action) => {
        state.nonComplianceByTypeLoading = true;
      })
      .addCase(securityHubNonComplianceByTypeAPI.fulfilled, (state, action) => {
        if (action.payload.status === 500) {
          state.nonComplianceByType = [];
          state.nonComplianceByTypeLoading = false;
        } else {
          state.nonComplianceByTypeLoading = false;
          state.nonComplianceByType = action.payload;
        }
      })

      .addCase(securityHubNonComplianceByTypeAPI.rejected, (state, action) => {
        state.nonComplianceByTypeLoading = false;
        state.nonComplianceByType = [];
      })


      .addCase(getSHFTargetMissedItems.pending, (state, action) => {
        state.securityHubTargetMissedItemsLoading = true;
      })
      .addCase(getSHFTargetMissedItems.fulfilled, (state, action) => {
        state.securityHubTargetMissedItems = action.payload;
        state.securityHubTargetMissedItemsLoading = false;
      })
      .addCase(getSHFTargetMissedItems.rejected, (state, action) => {
        state.securityHubTargetMissedItemsLoading = false;
        state.securityHubTargetMissedItems = [];
      })

      .addCase(getSHFTargetDueTrend.pending, (state, action) => {
        state.securityHubTargetDueTrendLoading = true;
      })
      .addCase(getSHFTargetDueTrend.fulfilled, (state, action) => {
        state.securityHubTargetDueTrend = action.payload;
        state.securityHubTargetDueTrendLoading = false;
      })
      .addCase(getSHFTargetDueTrend.rejected, (state, action) => {
        state.securityHubTargetDueTrend = [];
        state.securityHubTargetDueTrendLoading = false;
      })

      .addCase(getSHFOpenFindings.pending, (state, action) => {
        state.securityHubOpenFindingsLoading = true;
      })
      .addCase(getSHFOpenFindings.fulfilled, (state, action) => {
        state.securityHubOpenFindings = action.payload;
        state.securityHubOpenFindingsLoading = false;
      })
      .addCase(getSHFOpenFindings.rejected, (state, action) => {
        state.securityHubOpenFindings = [];
        state.securityHubOpenFindingsLoading = false;
      })

      .addCase(SHFFindingsTrend.pending, (state, action) => {
        state.SHFFindingsTrendLoading = true;
      })
      .addCase(SHFFindingsTrend.fulfilled, (state, action) => {
        state.SHFFindingsTrendData = action.payload;
        state.SHFFindingsTrendLoading = false;
      })
      .addCase(SHFFindingsTrend.rejected, (state, action) => {
        state.SHFFindingsTrendData = [];
        state.SHFFindingsTrendLoading = false;
      })

      .addCase(NonComplianceAppCri.pending, (state, action) => {
        state.NonComplianceAppCriLoading = true;
      })
      .addCase(NonComplianceAppCri.fulfilled, (state, action) => {
        state.NonComplianceAppCriData = action.payload;
        state.NonComplianceAppCriLoading = false;
      })
      .addCase(NonComplianceAppCri.rejected, (state, action) => {
        state.NonComplianceAppCriData = [];
        state.NonComplianceAppCriLoading = false;
      })

      .addCase(NonComplianceBySeverity.pending, (state, action) => {
        state.NonComplianceBySeverityLoading = true;
      })
      .addCase(NonComplianceBySeverity.fulfilled, (state, action) => {
        state.NonComplianceBySeverityData = action.payload;
        state.NonComplianceBySeverityLoading = false;
      })
      .addCase(NonComplianceBySeverity.rejected, (state, action) => {
        state.NonComplianceBySeverityData = [];
        state.NonComplianceBySeverityLoading = false;
      })

      .addCase(getSHFClosedFindings.pending, (state, action) => {
        state.securityHubClosedFindingsLoading = true;
      })
      .addCase(getSHFClosedFindings.fulfilled, (state, action) => {
        state.securityHubClosedFindings = action.payload;
        state.securityHubClosedFindingsLoading = false;
      })
      .addCase(getSHFClosedFindings.rejected, (state, action) => {
        state.securityHubClosedFindings = [];
        state.securityHubClosedFindingsLoading = false;
      })

      .addCase(FindingsDistribution.pending, (state, action) => {
        state.FindingDistributionloading = true;
      })
      .addCase(FindingsDistribution.fulfilled, (state, action) => {
        state.DistributionChart = action.payload;
        state.FindingDistributionloading = false;
      })
      .addCase(FindingsDistribution.rejected, (state, action) => {
        state.FindingDistributionloading = false;
      })

  },
});
export const { resetSecurityHubData } = SecurityHubSlice.actions;
export default SecurityHubSlice.reducer;
