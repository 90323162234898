import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseCode } from "./utilities/helpers";

export const getSecurityDetails = createAsyncThunk("get/SecurityDetails", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`/api/v1/aws/get/members/details`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        DTOP_API_TOKEN: apiToken,
        USERNAME: username,
      },
      // body: JSON.stringify(payload),
    });
    handleResponseCode(response);
    const data = await response.json();

    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

const commonSlice = createSlice({
  name: "common",
  initialState: {
    Assessment: "",
    isNavigatedUsingSideNav: true,
    sessionExtend: false,
    TimeoutId: null,
    sessionToken: false,
    currentPage: "Summary",
    offcanvasState: {
      isDrilldownOpen: false,
      title: "",
      dropDownMenuOptions: [],
      selectedValue: {},
      selectedData: [],
      handleDdMenuChange: () => { },
      isDropDownShown: true
    },
    offcanvasThreeState: {
      isDrilldownThreeOpen: false,
      locDrilldown: false,
      title: "",
      static: false,
      // dropDownMenuOptions: [],
      // selectedValue: {},
      selectedData: [],
      //handleDdMenuChange: () => {},
      isDropDownShown: true
    },
    accordionModal: {
      accordionData: []
    },
    offcanvasLastState: {
      isDrilldownLastOpen: false,
      //locDrilldown:false,
      title: "",
      static: false,
      // dropDownMenuOptions: [],
      // selectedValue: {},
      selectedData: [],
      //handleDdMenuChange: () => {},
      isDropDownShown: true
    },
    drillDownSelectionState: {
      selectedLevelOne: "",
    },
    completeTable: {
      limit: 10,
      pageNo: 0,
      pageCount: "",
      listCount: "",
      activeKey: "",
      searchKey: "",
      filterKey: "",
    },
    loggedUser: {
      userName: "",
      userRole: "",
      userMail: "",
      token: ""
    },
    UserMail: {
      mailId: ""
    },
    isReload: "false",
    assessmentData: [],
    selectedIds: [],
    pageLoading: true,
    securitydetailsLoading: false,
    secuirtyDetails: [],
    complianceAssessmentStates: {
      topic: null,
      subtopic: null,
      clause: null,
      questions: null
    }
  },
  reducers: {
    setIsOffCanvasOpen: {
      reducer(state, action) {
        state.offcanvasState = action.payload;
      },
    },
    setIsOffCanvasThreeOpen: {
      reducer(state, action) {
        state.offcanvasThreeState = action.payload;
      },
    },
    setIsOffCanvasLastOpen: {
      reducer(state, action) {
        state.offcanvasLastState = action.payload;
      },
    },
    setSelectedLevelOne: {
      reducer(state, action) {
        state.drillDownSelectionState.selectedLevelOne = action.payload;
      },
    },
    setAccordionData: {
      reducer(state, action) {
        state.accordionModal.accordionData = action.payload;
      }
    },
    setCompleteTableLimit: {
      reducer(state, action) {
        state.completeTable.limit = action.payload;
      },
    },
    setCompleteTablePageNo: {
      reducer(state, action) {
        state.completeTable.pageNo = action.payload;
      },
    },
    setCompleteTablepageCount: {
      reducer(state, action) {
        state.completeTable.pageCount = action.payload;
      },
    },
    setCompleteTablelistCount: {
      reducer(state, action) {
        state.completeTable.listCount = action.payload;
      },
    },
    setCompleteTableactiveKey: {
      reducer(state, action) {
        state.completeTable.activeKey = action.payload;
      },
    },
    setCompleteTableSearchKey: {
      reducer(state, action) {
        state.completeTable.searchKey = action.payload;
      },
    },
    setCompleteTableFilterKey: {
      reducer(state, action) {
        state.completeTable.filterKey = action.payload;
      },
    },
    hideOffCanvas: {
      reducer(state, action) {
        state.offcanvasState.isDrilldownOpen = action.payload;
      },
    },
    hideOffCanvasThree: {
      reducer(state, action) {
        state.offcanvasThreeState.isDrilldownThreeOpen = action.payload;
      },
    },
    hideOffCanvasLast: {
      reducer(state, action) {
        state.offcanvasLastState.isDrilldownLastOpen = action.payload;
      },
    },

    locdrilldownthree: {
      reducer(state, action) {
        state.offcanvasThreeState.locDrilldown = action.payload;
      },
    },
    setSelectedDdMenuValue: {
      reducer(state, action) {
        state.offcanvasState.selectedValue = action.payload;
      },
    },
    setSelectedData: {
      reducer(state, action) {
        state.offcanvasState.selectedData = action.payload;
      },
    },
    setLoggedUser: {
      reducer(state, action) {
        state.loggedUser = action.payload
      }
    },
    resetLoggedUser: {
      reducer(state, action) {
        state.loggedUser = {
          userName: "",
          userRole: "",
          userMail: "",
          token: ""
        };
        state.isNavigatedUsingSideNav = true;
      }
    },
    setUserMail: {
      reducer(state, action) {
        state.UserMail = action.payload
      }
    },
    setIsReload: {
      reducer(state, action) {
        state.isReload = action.payload
      }
    },
    setAssessmentData: {
      reducer(state, action) {
        state.assessmentData = action.payload
      }
    },
    setSelectedIds: {
      reducer(state, action) {
        state.selectedIds = action.payload
      }
    },
    setPageLoading: {
      reducer(state, action) {
        state.pageLoading = action.payload
      }
    },
    setNavigationClicked: {
      reducer(state, action) {
        state.isNavigatedUsingSideNav = action.payload;
      }
    },
    setCurrentPage: {
      reducer(state, action) {
        state.currentPage = action.payload;
      }
    },
    setTableReload: {
      reducer(state, action) {
        state.tableReload = action.payload
      }
    },
    setSessionExtend: {
      reducer(state, action) {
        state.sessionExtend = action.payload;
      }
    },
    setAssessment: {
      reducer(state, action) {
        state.Assessment = action.payload;
      }
    },
    setTimeoutId: {
      reducer(state, action) {
        state.TimeoutId = action.payload;
      }
    },
    setsessionToken: {
      reducer(state, action) {
        state.sessionToken = action.payload;
      }
    },
    setTopicStateInCA: {
      reducer(state, action) {
        state.complianceAssessmentStates.topic = action.payload;
      }
    },
    setSubTopicStateInCA: {
      reducer(state, action) {
        state.complianceAssessmentStates.subtopic = action.payload;
      }
    },
    setClauseStateInCA: {
      reducer(state, action) {
        state.complianceAssessmentStates.clause = action.payload;
      }
    },
    setQuestionStateInCA: {
      reducer(state, action) {
        state.complianceAssessmentStates.questions = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      //SecurityDetails
      .addCase(getSecurityDetails.pending, (state, action) => {
        state.securitydetailsLoading = true;
      })
      .addCase(getSecurityDetails.fulfilled, (state, action) => {
        state.secuirtyDetails = action.payload;
        state.securitydetailsLoading = false;
      })
      .addCase(getSecurityDetails.rejected, (state, action) => {
        state.securitydetailsLoading = false;
      })

  }
});
export const {
  setIsOffCanvasOpen,
  setIsOffCanvasThreeOpen,
  setIsOffCanvasLastOpen,
  setSelectedLevelOne,
  setCompleteTableLimit,
  setCompleteTablePageNo,
  setCompleteTablepageCount,
  setCompleteTablelistCount,
  setCompleteTableactiveKey,
  setCompleteTableSearchKey,
  setCompleteTableFilterKey,
  hideOffCanvas,
  hideOffCanvasThree,
  hideOffCanvasLast,
  locdrilldownthree,
  setSelectedDdMenuValue,
  setSelectedData,
  setLoggedUser,
  setUserMail,
  setIsReload,
  setPageLoading,
  setAssessmentData,
  setAssessment,
  setSelectedIds,
  setAccordionData,
  setNavigationClicked,
  setCurrentPage,
  setTableReload,
  resetLoggedUser,
  setSessionExtend,
  setTimeoutId,
  setsessionToken,
  setTopicStateInCA,
  setSubTopicStateInCA,
  setClauseStateInCA,
  setQuestionStateInCA
} = commonSlice.actions;


export default commonSlice.reducer;
