import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";

const cookies = new Cookies();

export const getIssueMetricsData = createAsyncThunk(
  "post/getData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/safeFlowMetrics/flow/peopleMetrics/issueMetric/main`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getIssueMetricsDrilldownData = createAsyncThunk(
  "post/getDrilldownData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, URL } = props;
      const response = await fetch(
        `${URL}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const peopleMetricsDDChart = createAsyncThunk(
  "post/peopleMetricsDDChart",
  async (props) => {
    try {

      const { payload, urlDDChart } = props;
      const response = await fetch(
        `${urlDDChart}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getIssueMetricsPopupTableData = createAsyncThunk(
  "post/getIssueMetricsPopupTableData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, urllist } = props;
      const response = await fetch(
        `${urllist}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getIssueMetricsTableData = createAsyncThunk(
  "post/getDrilldownTableData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, pageNumber, rowsPerPage, urllist } = props;
      const response = await fetch(
        `${urllist}${pageNumber}/${rowsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getIssueMetricsTableCount = createAsyncThunk(
  "post/getIssueMetricsTableCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, urlCount } = props;
      const response = await fetch(
        `${urlCount}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getDoneTableData = createAsyncThunk(
  "post/getDoneTableData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, pageNumber, rowsPerPage, urllist1 } = props;
      const response = await fetch(
        `${urllist1}${pageNumber}/${rowsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getDoneTableCount = createAsyncThunk(
  "post/getDoneTableCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, urlCount1 } = props;
      const response = await fetch(
        `${urlCount1}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getTopContributorsApi = createAsyncThunk(
  "post/getTopContributorsApi",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/PeopleDashboard/peopleMetric/topContributor/main`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getFollowup = createAsyncThunk(
  "post/getFollowup",
  async (props) => {
    const { payload, userName } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/PeopleDashboard/sprint/issueMetrics/followUp/${userName}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      const responseData = {
        data: data,
        payload: payload.issueKey
      };

      return response.ok ? responseData : Promise.reject(responseData);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getLevelOfCollaborationApi = createAsyncThunk(
  "post/getLevelOfCollaborationApi",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/PeopleDashboard/levelOfCollaborationByCommentsMain`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getTopAssigneesApi = createAsyncThunk(
  "post/getTopAssigneesApi",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/peopleMetrics/topAssigneeMainChartData`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getTopAssigneesDrilldown = createAsyncThunk(
  "post/getTopAssigneesDrilldown",
  async (props) => {
    const { payload, pageNumber, rowsPerPage } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/peopleMetrics/topAssigneeDrillDownOpenListWithSearchAndPagination/${pageNumber}/${rowsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getMergeReqMetricsApi = createAsyncThunk(
  "post/getMergeReqMetricsApi",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/peopleMetrics/mergeRequestMetricsChartData`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
const initialState = {
  issueMetricsData: [],
  isIssueMetricsLoading: false,
  getFollowupData: [],
  getFollowupLoading: false,
  issueMetricsError: "",
  drilldownLoading: false,
  drilldownOptionData: [],
  drilldownDataError: "",
  drilldownDoneData: [],
  drilldownPopupTableData: [],
  drilldownPopupTableLoading: [],
  drilldownTableLoading: false,
  drilldownDoneError: "",
  drilldownTableData: [],
  drilldownDoneLoading: false,
  drilldownTableCountDoneLoading: false,
  drilldownTableCountLoading: false,
  AssigneesData: [],
  AssigneesLoading: false,
  AssigneesDataDrilldown: [],
  AssigneesDrilldownLoading: false,
  MergeMetricsData: [],
  MergeMetricsLoading: false,
  TopContributorsData: [],
  TopContributorsLoading: false,
  LevelOfCollaborationData: [],
  LevelOfCollaborationLoading: false,
  drilldownTableError: "",
  drilldownTableCount: 0,
  drilldownChartData: [],
  drilldownChartLoading: false

};

const PeopleMetricsSlice = createSlice({
  name: "PeopleMetrics",
  initialState,

  reducers: {
    resetPeopleSliceData: () => {
      return initialState;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getIssueMetricsData.pending, (state, action) => {
        state.isIssueMetricsLoading = true;
        state.issueMetricsData = [];
        state.issueMetricsError = "";
      })
      .addCase(getIssueMetricsData.fulfilled, (state, action) => {
        state.isIssueMetricsLoading = false;
        state.issueMetricsData = action.payload;
      })
      .addCase(getIssueMetricsData.rejected, (state, action) => {
        state.isIssueMetricsLoading = false;
        state.issueMetricsError = action.payload;
      })
      //drilldown data
      .addCase(getIssueMetricsDrilldownData.pending, (state, action) => {
        state.drilldownLoading = true;
        state.drilldownOptionData = [];
        state.drilldownDataError = "";
        state.drilldownTableData = [];
        state.drilldownTableCount = 0;
      })
      .addCase(getIssueMetricsDrilldownData.fulfilled, (state, action) => {
        state.drilldownLoading = false;
        state.drilldownOptionData = action.payload;
      })
      .addCase(getIssueMetricsDrilldownData.rejected, (state, action) => {
        state.drilldownLoading = false;
        state.drilldownDataError = action.payload;
      })
      //Assignees data
      .addCase(getTopAssigneesApi.pending, (state, action) => {
        state.AssigneesLoading = true;
        state.AssigneesData = [];
      })
      .addCase(getTopAssigneesApi.fulfilled, (state, action) => {
        state.AssigneesLoading = false;
        state.AssigneesData = action.payload;
      })
      .addCase(getTopAssigneesApi.rejected, (state, action) => {
        state.AssigneesLoading = false;
        state.AssigneesData = action.payload;
      })
      //Assignees Drilldown data
      .addCase(getTopAssigneesDrilldown.pending, (state, action) => {
        state.AssigneesDrilldownLoading = true;
        state.AssigneesDataDrilldown = [];
      })
      .addCase(getTopAssigneesDrilldown.fulfilled, (state, action) => {
        state.AssigneesDrilldownLoading = false;
        state.AssigneesDataDrilldown = action.payload;
      })
      .addCase(getTopAssigneesDrilldown.rejected, (state, action) => {
        state.AssigneesDrilldownLoading = false;
        state.AssigneesDataDrilldown = action.payload;
      })
      //getMergeReqMetricsApi data
      .addCase(getMergeReqMetricsApi.pending, (state, action) => {
        state.MergeMetricsLoading = true;
        state.MergeMetricsData = [];
      })
      .addCase(getMergeReqMetricsApi.fulfilled, (state, action) => {
        state.MergeMetricsLoading = false;
        state.MergeMetricsData = action.payload;
      })
      .addCase(getMergeReqMetricsApi.rejected, (state, action) => {
        state.MergeMetricsLoading = false;
        state.MergeMetricsData = action.payload;
      })
      //drill chart data
      .addCase(peopleMetricsDDChart.pending, (state, action) => {
        state.drilldownChartLoading = true;
      })
      .addCase(peopleMetricsDDChart.fulfilled, (state, action) => {
        state.drilldownChartLoading = false;
        state.drilldownChartData = action.payload;
      })
      .addCase(peopleMetricsDDChart.rejected, (state, action) => {
        state.drilldownChartLoading = false;
      })

      //Table Popup data
      .addCase(getIssueMetricsPopupTableData.pending, (state, action) => {
        state.drilldownPopupTableLoading = true;
      })
      .addCase(getIssueMetricsPopupTableData.fulfilled, (state, action) => {
        state.drilldownPopupTableLoading = false;
        state.drilldownPopupTableData = action.payload;
      })
      .addCase(getIssueMetricsPopupTableData.rejected, (state, action) => {
        state.drilldownPopupTableLoading = false;
      })
      //Table data
      .addCase(getIssueMetricsTableData.pending, (state, action) => {
        state.drilldownTableLoading = true;
      })
      .addCase(getIssueMetricsTableData.fulfilled, (state, action) => {
        state.drilldownTableLoading = false;
        state.drilldownTableData = action.payload;
      })
      .addCase(getIssueMetricsTableData.rejected, (state, action) => {
        state.drilldownTableLoading = false;
        state.drilldownTableError = action.payload;
      })
      .addCase(getIssueMetricsTableCount.pending, (state, action) => {
        state.drilldownTableCount = 0;
        state.drilldownTableCountLoading = true;
      })
      .addCase(getIssueMetricsTableCount.fulfilled, (state, action) => {
        state.drilldownTableCountLoading = false;
        state.drilldownTableCount = action.payload;
      })
      .addCase(getIssueMetricsTableCount.rejected, (state, action) => {
        state.drilldownTableCountLoading = false;
      })
      //Table Done data

      .addCase(getDoneTableData.fulfilled, (state, action) => {
        state.drilldownDoneLoading = false;
        state.drilldownDoneData = action.payload;
      })
      .addCase(getDoneTableData.rejected, (state, action) => {
        state.drilldownDoneLoading = false;
        state.drilldownDoneError = action.payload;
      })
      .addCase(getDoneTableCount.pending, (state, action) => {
        state.drilldownDoneCount = 0;
        state.drilldownTableCountDoneLoading = true;
      })
      .addCase(getDoneTableCount.fulfilled, (state, action) => {
        state.drilldownTableCountDoneLoading = false;
        state.drilldownDoneCount = action.payload;
      })
      .addCase(getDoneTableCount.rejected, (state, action) => {
        state.drilldownTableCountDoneLoading = false;
      })

      //Top Contributors
      .addCase(getTopContributorsApi.pending, (state, action) => {
        state.TopContributorsLoading = true;
      })
      .addCase(getTopContributorsApi.fulfilled, (state, action) => {
        state.TopContributorsData = action.payload;
        state.TopContributorsLoading = false;
      })
      .addCase(getTopContributorsApi.rejected, (state, action) => {
        state.TopContributorsLoading = false;
      })

      //Level Of Collaboration
      .addCase(getLevelOfCollaborationApi.pending, (state, action) => {
        state.LevelOfCollaborationLoading = true;
      })
      .addCase(getLevelOfCollaborationApi.fulfilled, (state, action) => {
        state.LevelOfCollaborationData = action.payload;
        state.LevelOfCollaborationLoading = false;
      })
      .addCase(getLevelOfCollaborationApi.rejected, (state, action) => {
        state.LevelOfCollaborationLoading = false;
      })
      //Follow Up
      .addCase(getFollowup.pending, (state, action) => {
        state.getFollowupLoading = true;
      })
      .addCase(getFollowup.fulfilled, (state, action) => {
        state.getFollowupData = action.payload;
        state.getFollowupLoading = false;
      })
      .addCase(getFollowup.rejected, (state, action) => {
        state.getFollowupLoading = false;
      })


  }
})

export const { resetPeopleSliceData } = PeopleMetricsSlice.actions;

export default PeopleMetricsSlice.reducer;