import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";


const cookies = new Cookies();

//main table list
export const RiskassMaintableApi = createAsyncThunk(
  "post/RiskassMaintableApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/riskAssessmentPolicy/getRiskAssessmentPolicyListWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//maintable count

export const RiskassMaintableCountApi = createAsyncThunk(
  "post/RiskassMaintableCountApi",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/riskAssessmentPolicy/getRiskAssessmentPolicyListWithPaginationAndSearchForCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


//topic table list
export const RiskassTopictableApi = createAsyncThunk(
  "post/RiskassTopictableApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payloadTopic, policyId, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/riskAssessmentTopic/riskAssessmentTopicList/${policyId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payloadTopic),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//topic count
export const RiskassTopicTableCountApi = createAsyncThunk(
  "post/RiskassTopicTableCountApi",
  async (props) => {
    const { payloadTopic, policyId } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/riskAssessmentTopic/riskAssessmentTopicListCount/${policyId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payloadTopic),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


// subtopic table list
export const RiskassSubTopictableApi = createAsyncThunk(
  "post/RiskassSubTopictableApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payloadSubTopic, policyId, topicsId, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/riskAssessmentSubTopic/riskAssessmentSubTopicList/${policyId}/${topicsId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payloadSubTopic),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//sub topic count
export const RiskassSubTopicTableCountApi = createAsyncThunk(
  "post/RiskassSubTopicTableCountApi",
  async (props) => {
    const { payloadSubTopic, policyId, topicsId } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/riskAssessmentSubTopic/riskAssessmentSubTopicListCount/${policyId}/${topicsId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payloadSubTopic),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

// clause table list
export const RiskassClauseListApi = createAsyncThunk(
  "post/RiskassClauseListApi",
  async (props) => {
    const { clausePayload, policyId, topicId, subTopicId, pageNumber, itemsPerPage } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/riskAssessmentClause/riskAssessmentClauseList/${policyId}/${topicId}/${subTopicId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(clausePayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//clause count
export const RiskassClauseCountApi = createAsyncThunk(
  "post/RiskassClauseCountApi",
  async (props) => {
    const { clausePayload, policyId, topicId, subTopicId } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/riskAssessmentClause/riskAssessmentClauseListCount/${policyId}/${topicId}/${subTopicId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(clausePayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

// Assessment table list
export const RiskassAssessmenttableApi = createAsyncThunk(
  "post/RiskassAssessmenttableApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payloadAssessment, pageNumber, itemsPerPage } = props;
      console.log(props, "========")
      const response = await fetch(
        `/api/v1/riskAssessmentQuestions/assessmentQuestionList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payloadAssessment),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Assessment count
export const RiskassAssessmentTableCountApi = createAsyncThunk(
  "post/RiskassAssessmentTableCountApi",
  async (props) => {
    const { payloadAssessment } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/riskAssessmentQuestions/assessmentQuestionCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payloadAssessment),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const PolicyInfoSave = createAsyncThunk(
  "post/PolicyInfoSave",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      console.log(payload)
      console.log(username)

      const response = await fetch(
        `/api/v1/riskAssessmentPolicy/savePolicyName/${username}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const PolicyInfodelete = createAsyncThunk(
  "post/PolicyInfodelete",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { userName, policyId } = props;
      const response = await fetch(
        ` /api/v1/riskAssessmentPolicy/getRiskAssessmentPolicyForDelete/${policyId}/${userName}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);


export const TopicInfoSave = createAsyncThunk(
  "post/TopicInfoSave",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      console.log(props)
      const { payload, PolicyId } = props;
      console.log(payload)
      console.log(PolicyId)
      const response = await fetch(
        `/api/v1/riskAssessmentTopic/saveOrEditRiskAssessmentTopic/${PolicyId}/${username}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const TopicInfodelete = createAsyncThunk(
  "post/TopicInfodelete",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { userName, id } = props;
      console.log(props)
      console.log(id)
      const response = await fetch(

        `/api/v1/riskAssessmentTopic/deleteRiskAssessmentTopic/${id}/${userName}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

export const SubTopicInfoSave = createAsyncThunk(
  "post/SubTopicInfoSave",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // console.log(props)
      const { payload, PolicyId, topicid } = props;
      // console.log(payload)
      // console.log(PolicyId)

      // console.log(userName)

      const response = await fetch(
        `/api/v1/riskAssessmentSubTopic/saveOrEditRiskAssessmentSubTopic/${PolicyId}/${topicid}/${username}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });


export const SubTopicInfodelete = createAsyncThunk(
  "post/SubTopicInfodelete",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id } = props;
      console.log(props)
      const response = await fetch(

        `/api/v1/riskAssessmentSubTopic/deleteRiskAssessmentSubTopic/${id}/${username}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

export const ClauseInfoSave = createAsyncThunk(
  "post/ClauseInfoSave",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");

    try {
      // Destructure props
      const { payload, PolicyId, topicid, Stopicid } = props;

      // Make sure PolicyId, topicid, Stopicid are correctly passed and logged
      console.log("Clause Save Props:", props);

      // API request
      const response = await fetch(
        `/api/v1/riskAssessmentClause/saveOrEditRiskAssessmentClause/${PolicyId}/${topicid}/${Stopicid}/${username}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );

      // Check response code, handle non-OK responses
      if (!response.ok) {
        throw new Error(`Error! Status: ${response.status}`);
      }

      // Parse response
      const data = await response.json();
      return data;

    } catch (error) {
      console.error("Clause Save Error:", error);
      throw error; // Propagate error for rejected case
    }
  }
);

export const ClauseInfodelete = createAsyncThunk(
  "post/ClauseInfodelete",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const userName = cookies.get("username");
    try {
      const { id } = props;
      const response = await fetch(

        `/api/v1/riskAssessmentClause/deleteRiskAssessmentClause/${id}/${userName}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: userName,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

export const AssessmentInfoSave = createAsyncThunk(
  "post/AssessmentInfoSave",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, PolicyId, topicid, Stopicid, clauseId } = props;
      // console.log(payload)
      // console.log(PolicyId)   
      // console.log(userName)

      const response = await fetch(
        `/api/v1/riskAssessmentQuestions/savaAssessmentQuestions/${PolicyId}/${topicid}/${Stopicid}/${clauseId}/${username}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const AssessmentInfodelete = createAsyncThunk(
  "post/AssessmentInfodelete",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id } = props;
      console.log(props)
      const response = await fetch(

        `/api/v1/riskAssessmentQuestions/deleteassessmentQuestion/${id}/${username}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);


export const DropdownPolicyList = createAsyncThunk(
  "get/DropdownPolicyList",
  async (props) => {
    const { PolicyId } = props
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      console.log(props)

      const response = await fetch(
        `/api/v1/riskAssessmentTopic/getAllTopicsAndSubTopics/${PolicyId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          }
        }
      );
      // console.log(response)
      handleResponseCode(response);
      const data = await response.json();
      // console.log(data)
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


export const ImportCSV = createAsyncThunk(
  "post/ImportCSV",
  async (props, { rejectWithValue }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    const { formData } = props;
    try {
      const response = await fetch(
        `/api/v1/riskAssessmentPolicy/importCSV/${username}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        return rejectWithValue(errorResponse);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(error.message);
    }
  }
);



export const RiskassessmentSlice = createSlice({
  name: "RiskassessmentSlice",
  initialState: {

    PolicyDeleteLoading: false,
    PolicyDataDelete: {},

    TopoicDeleteLoading: false,
    TopicDataDelete: {},

    SubTopicDeleteLoading: false,
    SubTopicDataDelete: {},

    ClauseDeleteLoading: false,
    ClauseDataDelete: {},

    AssessmentDeleteLoading: false,
    AssessmentDataDelete: {},

    DropdownDataLoading: false,
    DropdownData: [],


    RiskassMaintableListData: [],
    RiskassMaintableListDataLoading: false,
    RiskassMaintableCountData: [],
    RiskassMaintableCounDataLoading: false,

    RiskassTopictableListData: [],
    RiskassTopictableListDataLoading: false,
    RiskassToipctableCounData: [],
    RiskassToipctableCounDataLoading: false,

    RiskassSubTopictableListData: [],
    RiskassSubTopictableListDataLoading: false,
    RiskassSubToipctableCounData: [],
    RiskassSubToipctableCounDataLoading: false,

    RiskassClauseListData: [],
    RiskassClauseListDataLoading: false,
    RiskassClauseCounData: [],
    RiskassClauseCounDataLoading: false,

    RiskassAssessemntListData: [],
    RiskassAssessemntListDataLoading: false,
    RiskassAssessmentCounData: [],
    RiskassAssessmentCounDataLoading: false,

    PolicyDataSave: "",
    policyerrorSave: "",
    PolicysaveLoading: false,

    TopicDataSave: "",
    TopicsaveLoading: false,
    TopicerrorSave: "",

    SubTopicDataSave: "",
    SubTopicsaveLoading: false,
    SubTopicerrorSave: "",

    ClauseDataSave: "",
    ClauseSaveLoading: false,
    ClauseDataSaveError: "",

    AssessmentDataSave: "",
    AssessemntsaveLoading: false,
    AssessmenterrorSave: "",

    CSVDataSave: "",
    CSVDataSaveLoading: false,
    CSVDataSaveError: "",

  },

  reducers: {
    resetRiskassessmentData: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder


      //MainTableList 

      .addCase(RiskassMaintableApi.pending, (state, action) => {
        state.RiskassMaintableListDataLoading = true;
      })
      .addCase(RiskassMaintableApi.fulfilled, (state, action) => {
        state.RiskassMaintableListData = action.payload;
        state.RiskassMaintableListDataLoading = false;
      })
      .addCase(RiskassMaintableApi.rejected, (state, action) => {
        state.RiskassMaintableListData = [];
        state.RiskassMaintableListDataLoading = false;
      })

      //maintable count
      .addCase(RiskassMaintableCountApi.pending, (state, action) => {
        state.RiskassMaintableCounDataLoading = true;
      })
      .addCase(RiskassMaintableCountApi.fulfilled, (state, action) => {
        state.RiskassMaintableCountData = action.payload;
        state.RiskassMaintableCounDataLoading = false;
      })
      .addCase(RiskassMaintableCountApi.rejected, (state, action) => {
        state.RiskassMaintableCounDataLoading = false;
        state.RiskassMaintableCountData = [];

      })

      //TopicTableList 

      .addCase(RiskassTopictableApi.pending, (state, action) => {
        state.RiskassTopictableListDataLoading = true;
      })
      .addCase(RiskassTopictableApi.fulfilled, (state, action) => {
        state.RiskassTopictableListData = action.payload;
        state.RiskassTopictableListDataLoading = false;
      })
      .addCase(RiskassTopictableApi.rejected, (state, action) => {
        state.RiskassTopictableListData = [];
        state.RiskassTopictableListDataLoading = false;
      })


      //topictable count
      .addCase(RiskassTopicTableCountApi.pending, (state, action) => {
        state.RiskassToipctableCounDataLoading = true;
      })
      .addCase(RiskassTopicTableCountApi.fulfilled, (state, action) => {
        state.RiskassToipctableCounData = action.payload;
        state.RiskassToipctableCounDataLoading = false;
      })
      .addCase(RiskassTopicTableCountApi.rejected, (state, action) => {
        state.RiskassToipctableCounDataLoading = false;
        state.RiskassToipctableCounData = [];

      })

      //SubTopicTableList 

      .addCase(RiskassSubTopictableApi.pending, (state, action) => {
        state.RiskassSubTopictableListDataLoading = true;
      })
      .addCase(RiskassSubTopictableApi.fulfilled, (state, action) => {
        state.RiskassSubTopictableListData = action.payload;
        state.RiskassSubTopictableListDataLoading = false;
      })
      .addCase(RiskassSubTopictableApi.rejected, (state, action) => {
        state.RiskassSubTopictableListData = [];
        state.RiskassSubTopictableListDataLoading = false;
      })

      //Subtopictable count
      .addCase(RiskassSubTopicTableCountApi.pending, (state, action) => {
        state.RiskassSubToipctableCounDataLoading = true;
      })
      .addCase(RiskassSubTopicTableCountApi.fulfilled, (state, action) => {
        state.RiskassSubToipctableCounData = action.payload;
        state.RiskassSubToipctableCounDataLoading = false;
      })
      .addCase(RiskassSubTopicTableCountApi.rejected, (state, action) => {
        state.RiskassSubToipctableCounDataLoading = false;
        state.RiskassSubToipctableCounData = [];

      })

      //ClauseTableList 

      .addCase(RiskassClauseListApi.pending, (state, action) => {
        state.RiskassClauseListDataLoading = true;
      })
      .addCase(RiskassClauseListApi.fulfilled, (state, action) => {
        state.RiskassClauseListData = action.payload;
        state.RiskassClauseListDataLoading = false;
      })
      .addCase(RiskassClauseListApi.rejected, (state, action) => {
        state.RiskassClauseListData = [];
        state.RiskassClauseListDataLoading = false;
      })

      //Clause table count
      .addCase(RiskassClauseCountApi.pending, (state, action) => {
        state.RiskassClauseCounDataLoading = true;
      })
      .addCase(RiskassClauseCountApi.fulfilled, (state, action) => {
        state.RiskassClauseCounData = action.payload;
        state.RiskassClauseCounDataLoading = false;
      })
      .addCase(RiskassClauseCountApi.rejected, (state, action) => {
        state.RiskassClauseCounDataLoading = false;
        state.RiskassClauseCounData = [];

      })


      //Assessment TableList 

      .addCase(RiskassAssessmenttableApi.pending, (state, action) => {
        state.RiskassAssessemntListDataLoading = true;
      })
      .addCase(RiskassAssessmenttableApi.fulfilled, (state, action) => {
        state.RiskassAssessemntListData = action.payload;
        state.RiskassAssessemntListDataLoading = false;
      })
      .addCase(RiskassAssessmenttableApi.rejected, (state, action) => {
        state.RiskassAssessemntListData = [];
        state.RiskassAssessemntListDataLoading = false;
      })

      //Assessment table count
      .addCase(RiskassAssessmentTableCountApi.pending, (state, action) => {
        state.RiskassAssessmentCounDataLoading = true;
      })
      .addCase(RiskassAssessmentTableCountApi.fulfilled, (state, action) => {
        state.RiskassAssessmentCounData = action.payload;
        state.RiskassAssessmentCounDataLoading = false;
      })
      .addCase(RiskassAssessmentTableCountApi.rejected, (state, action) => {
        state.RiskassAssessmentCounDataLoading = false;
        state.RiskassAssessmentCounData = [];

      })

      //Policy save

      .addCase(PolicyInfoSave.pending, (state, action) => {
        state.PolicyDataSave = "";
        state.PolicysaveLoading = true;
      })
      .addCase(PolicyInfoSave.fulfilled, (state, action) => {
        state.PolicyDataSave = action.payload;
        state.PolicysaveLoading = false;
      })
      .addCase(PolicyInfoSave.rejected, (state, action) => {
        state.PolicysaveLoading = false;
        state.PolicyDataSave = "";
        state.policyerrorSave = "Something went wrong";
      })

      //policy delete

      .addCase(PolicyInfodelete.pending, (state, action) => {
        state.PolicyDeleteLoading = true;
      })
      .addCase(PolicyInfodelete.fulfilled, (state, action) => {
        state.PolicyDeleteLoading = false;
        state.PolicyDataDelete = action.payload;
      })
      .addCase(PolicyInfodelete.rejected, (state, action) => {
        state.PolicyDeleteLoading = false;
        state.errorDelete = "Something went wrong";
      })


      //Topic save

      .addCase(TopicInfoSave.pending, (state, action) => {
        state.TopicDataSave = "";
        state.TopicsaveLoading = true;
      })
      .addCase(TopicInfoSave.fulfilled, (state, action) => {
        state.TopicDataSave = action.payload;
        state.TopicsaveLoading = false;
      })
      .addCase(TopicInfoSave.rejected, (state, action) => {
        state.TopicsaveLoading = false;
        state.TopicDataSave = "";
        state.TopicerrorSave = "Something went wrong";
      })

      //topic delete

      .addCase(TopicInfodelete.pending, (state, action) => {
        state.TopoicDeleteLoading = true;
      })
      .addCase(TopicInfodelete.fulfilled, (state, action) => {
        state.TopoicDeleteLoading = false;
        state.TopicDataDelete = action.payload;
      })
      .addCase(TopicInfodelete.rejected, (state, action) => {
        state.TopoicDeleteLoading = false;
        state.errorDelete = "Something went wrong";
      })

      // sub topic save
      .addCase(SubTopicInfoSave.pending, (state, action) => {
        state.SubTopicDataSave = "";
        state.SubTopicsaveLoading = true;
      })
      .addCase(SubTopicInfoSave.fulfilled, (state, action) => {
        state.SubTopicDataSave = action.payload;
        state.SubTopicsaveLoading = false;
      })
      .addCase(SubTopicInfoSave.rejected, (state, action) => {
        state.SubTopicsaveLoading = false;
        state.SubTopicDataSave = "";
        state.SubTopicerrorSave = "Something went wrong";
      })

      //Sub topic delete

      .addCase(SubTopicInfodelete.pending, (state, action) => {
        state.SubTopicDeleteLoading = true;
      })
      .addCase(SubTopicInfodelete.fulfilled, (state, action) => {
        state.SubTopicDeleteLoading = false;
        state.SubTopicDataDelete = action.payload;
      })
      .addCase(SubTopicInfodelete.rejected, (state, action) => {
        state.SubTopicDeleteLoading = false;
        state.errorDelete = "Something went wrong";
      })


      // clause save

      .addCase(ClauseInfoSave.pending, (state) => {
        state.ClauseDataSave = "";
        state.ClauseSaveLoading = true;
      })
      .addCase(ClauseInfoSave.fulfilled, (state, action) => {
        state.ClauseDataSave = action.payload; // Store the successful payload
        state.ClauseSaveLoading = false;
      })
      .addCase(ClauseInfoSave.rejected, (state, action) => {
        state.ClauseSaveLoading = false;
        state.ClauseDataSave = "";
        state.ClauseDataSaveError = action.error.message || "Something went wrong";
      })

      //Clause delete

      .addCase(ClauseInfodelete.pending, (state, action) => {
        state.ClauseDeleteLoading = true;
      })
      .addCase(ClauseInfodelete.fulfilled, (state, action) => {
        state.ClauseDeleteLoading = false;
        state.ClauseDataDelete = action.payload;
      })
      .addCase(ClauseInfodelete.rejected, (state, action) => {
        state.ClauseDeleteLoading = false;
        state.errorDelete = "Something went wrong";
      })

      //assessment save

      .addCase(AssessmentInfoSave.pending, (state, action) => {
        state.AssessmentDataSave = "";
        state.AssessemntsaveLoading = true;
      })
      .addCase(AssessmentInfoSave.fulfilled, (state, action) => {
        state.AssessmentDataSave = action.payload;
        state.AssessemntsaveLoading = false;
      })
      .addCase(AssessmentInfoSave.rejected, (state, action) => {
        state.AssessemntsaveLoading = false;
        state.AssessmentDataSave = "";
        state.AssessmenterrorSave = "Something went wrong";
      })


      //Assessment delete

      .addCase(AssessmentInfodelete.pending, (state, action) => {
        state.AssessmentDeleteLoading = true;
      })
      .addCase(AssessmentInfodelete.fulfilled, (state, action) => {
        state.AssessmentDeleteLoading = false;
        state.AssessmentDataDelete = action.payload;
      })
      .addCase(AssessmentInfodelete.rejected, (state, action) => {
        state.AssessmentDeleteLoading = false;
        state.errorDelete = "Something went wrong";
      })


      .addCase(DropdownPolicyList.pending, (state, action) => {
        state.DropdownDataLoading = true;
      })
      .addCase(DropdownPolicyList.fulfilled, (state, action) => {
        console.log(action)
        state.DropdownDataLoading = false;
        state.DropdownData = action.payload;
      })
      .addCase(DropdownPolicyList.rejected, (state, action) => {
        state.DropdownDataLoading = false;
        state.DropdownData = []
      })

      //Import csv

      .addCase(ImportCSV.pending, (state, action) => {
        state.CSVDataSave = "";
        state.CSVDataSaveLoading = true;
      })
      .addCase(ImportCSV.fulfilled, (state, action) => {
        state.CSVDataSave = action.payload;
        state.CSVDataSaveLoading = false;
      })
      .addCase(ImportCSV.rejected, (state, action) => {
        state.CSVDataSaveLoading = false;
        state.CSVDataSave = "";
        state.CSVDataSaveError = "Something went wrong";
      })


  },
});
export const { resetRiskassessmentData } = RiskassessmentSlice.actions;
export default RiskassessmentSlice.reducer;