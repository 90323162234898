import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../app/utilities/helpers";

const cookies = new Cookies();

export const businessInfoList = createAsyncThunk(
  "post/businessInfoList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/businessInfoConfig/list/BusinessInfoConfigSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const businessInfoCount = createAsyncThunk(
  "post/getDatas",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        ` /api/v1/businessInfoConfig/list/BusinessInfoConfigSearchCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const AppList = createAsyncThunk("post/getDatasApp", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const { userName, userRole } = props;
    const response = await fetch(` /api/v1/cmdb/${userName}/${userRole}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        DTOP_API_TOKEN: apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
});

export const BusinessInfoSave = createAsyncThunk("post/save", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const { userName, payload } = props;

    const response = await fetch(
      `/api/v1/businessInfoConfig/saveOrUpdateBusinessConfigInfo/${userName}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
    );
    handleResponseCode(response);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const Businessdelete = createAsyncThunk(
  "post/deleteinfo",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { userName, id } = props;
      const response = await fetch(
        ` /api/v1/businessInfoConfig/delete/${id}/${userName}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

export const BusinessEdit = createAsyncThunk(
  "post/getdataedit",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id } = props;
      const response = await fetch(` /api/v1/businessInfoConfig/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
      });
      handleResponseCode(response);
      const data = await response.json();

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const getTableFilter = createAsyncThunk(
  "post/getTableFilter",
  async () => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`/api/v1/businessInfoConfig/list/BusinessInfoConfig/division`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
      });
      handleResponseCode(response);
      const data = await response.json();

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const userInfoListApi = createAsyncThunk(
  "post/userInfoListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`/api/v1/LDAPDashboard/UserInfoForCmdb/${pageNumber}/${itemsPerPage}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      )
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const userInfoCountApi = createAsyncThunk(
  "post/userInfoCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/LDAPDashboard/UserInfoForCmdbCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const serverInfoListApi = createAsyncThunk(
  "post/serverInfoListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`/api/v1/serverConfigInfo/listByDevice/${pageNumber}/${itemsPerPage}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const serverInfoCountApi = createAsyncThunk(
  "post/serverInfoCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/serverConfigInfo/listCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const cloudAccountListApi = createAsyncThunk(
  "post/cloudAccountListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`/api/v1/awsIam/CSPAccountListForSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const cloudAccountCountApi = createAsyncThunk(
  "post/cloudAccountCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/awsIam/CSPAccountListForSearchCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const cloudAccountTestConnection = createAsyncThunk(
  "post/cloudAccountTestConnection",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      console.log(props);
      const response = await fetch(`/api/v1/awsIam/checkAccountForTestConnection`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(props),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const osLicenseConfigListApi = createAsyncThunk(
  "post/osLicenseConfigListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`/api/v1/licenceConfigForServerRest/getListWithSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const osLicenseConfigCountApi = createAsyncThunk(
  "post/osLicenseConfigCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/licenceConfigForServerRest/getListCountWithSearchAndPagination`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const isLdapUserCheckAPI = createAsyncThunk(
  "post/isLdapUserCheckAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`/api/v1/LDAPDashboard/checkLDAPConfig`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const ldapSyncAPI = createAsyncThunk(
  "post/ldapSyncAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`/api/v1/LDAPDashboard/LDAPDashboardDataSave`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const userSearchList  = createAsyncThunk(
  "get/userSearchList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`/api/v1/UserAccessRest/usersList`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const saveReviewerConfiguration = createAsyncThunk(
  "post/saveReviewerConfiguration",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payLoad } = props;
      console.log(payLoad)
      const response = await fetch(`/api/v1/UserAccessRest/saveReviewConfig`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payLoad),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const reviewersList  = createAsyncThunk(
  "get/reviewersList",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/UserAccessRest/getReviewersByApplication/${cmdbId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const reviewConfiguration  = createAsyncThunk(
  "get/reviewConfiguration",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/UserAccessRest/getReviewConfigByApplication/${cmdbId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)


const CMDBSlice = createSlice({
  name: "CmdbId",
  initialState: {
    getCmdbId: "",
    businessInfoData: [],
    bILoading: false,
    businessInfoDataCount: [],
    Applist: [],
    ApplistLoading: false,
    bICountLoading: false,
    saveLoading: false,
    DataSave: "",
    deleteLoading: false,
    editLoading: false,
    responseData: [],
    tableFilterData: [],
    error: "",
    errorEdit: "",
    errorCount: "",
    errorApp: "",
    errorSave: "",
    errorDelete: "",
    DataDelete: {},
    userInfoListLoading: false,
    userInfoListData: [],
    userInfoCountLoading: false,
    userInfoCountData: [],
    serverInfoListLoading: false,
    serverInfoListData: [],
    serverInfoCountLoading: false,
    serverInfoCountData: [],
    cloudAccountListLoading: false,
    cloudAccountListData: [],
    cloudAccountCountLoading: false,
    cloudAccountCountData: [],
    osLicenseConfigListLoading: false,
    osLicenseConfigListData: [],
    osLicenseConfigCountLoading: false,
    osLicenseConfigCountData: [],
    isLdapUser: false,
    isLdapUserLoading: false,
    ldapSyncdata: [],
    ldapSyncLoading: false,
    ldapSyncdataFulFill: false,
    userSearchListLoading:false,
    userSearchListData:[],
    saveReviewerConfigurationLoading:false,
    saveReviewerConfigurationData:[],
    reviewersListLoading:false,
    reviewersListData:[],
    reviewConfigurationLoading:false,
    reviewConfigurationData:[]
  },
  reducers: {
    setCmdbId: (state, action) => {
      state.getCmdbId = action.payload;
    },
    resetDataSave: (state) => {
      state.DataSave = "";
    },
    resetldapSyncStatus: (state) => {
      state.ldapSyncdataFulFill = false;
      state.ldapSyncdata = ''
    },
    resetresponseData: (state) => {
      state.responseData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(businessInfoList.pending, (state, action) => {
        state.bILoading = true;
      })
      .addCase(businessInfoList.fulfilled, (state, action) => {
        state.businessInfoData = action.payload;
        state.bILoading = false;
      })
      .addCase(businessInfoList.rejected, (state, action) => {
        state.bILoading = false;
        state.businessInfoData = [];
        state.error = "Something went wrong";
      })

      .addCase(businessInfoCount.pending, (state, action) => {
        state.bICountLoading = true;
      })
      .addCase(businessInfoCount.fulfilled, (state, action) => {
        state.businessInfoDataCount = action.payload;
        state.bICountLoading = false;
      })
      .addCase(businessInfoCount.rejected, (state, action) => {
        state.bICountLoading = false;
        state.businessInfoDataCount = [];
        state.errorCount = "Something went wrong";
      })

      .addCase(AppList.pending, (state, action) => {
        state.ApplistLoading = true;
      })
      .addCase(AppList.fulfilled, (state, action) => {
        state.Applist = action.payload;
        state.ApplistLoading = false;
      })
      .addCase(AppList.rejected, (state, action) => {
        state.ApplistLoading = false;
        state.Applist = [];
        state.errorApp = "Something went wrong";
      })

      .addCase(BusinessInfoSave.pending, (state, action) => {
        state.DataSave = "";
        state.saveLoading = true;
      })
      .addCase(BusinessInfoSave.fulfilled, (state, action) => {
        state.DataSave = action.payload;
        state.saveLoading = false;
      })
      .addCase(BusinessInfoSave.rejected, (state, action) => {
        state.saveLoading = false;
        state.DataSave = "";
        state.errorSave = "Something went wrong";
      })

      .addCase(Businessdelete.pending, (state, action) => {
        state.deleteLoading = true;
      })
      .addCase(Businessdelete.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.DataDelete = action.payload;
      })
      .addCase(Businessdelete.rejected, (state, action) => {
        state.deleteLoading = false;
        state.errorDelete = "Something went wrong";
      })

      .addCase(BusinessEdit.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(BusinessEdit.fulfilled, (state, action) => {
        state.responseData = action.payload;
        state.editLoading = false;
      })
      .addCase(BusinessEdit.rejected, (state, action) => {
        state.editLoading = false;
        state.responseData = [];
        state.errorEdit = "Something went error";
      })

      .addCase(getTableFilter.fulfilled, (state, action) => {
        state.tableFilterData = action.payload;
      })

      // userInfo List
      .addCase(userInfoListApi.pending, (state, action) => {
        state.userInfoListLoading = true;
      })
      .addCase(userInfoListApi.fulfilled, (state, action) => {
        state.userInfoListData = action.payload;
        state.userInfoListLoading = false;
        state.ldapSyncdata = [];
        state.ldapSyncdataFulFill = false;
      })
      .addCase(userInfoListApi.rejected, (state, action) => {
        state.userInfoListLoading = false;
        state.userInfoListData = [];
        state.ldapSyncdata = [];
        state.ldapSyncdataFulFill = false;
      })

      .addCase(userInfoCountApi.pending, (state, action) => {
        state.userInfoCountLoading = true;
      })
      .addCase(userInfoCountApi.fulfilled, (state, action) => {
        state.userInfoCountData = action.payload;
        state.userInfoCountLoading = false;
      })
      .addCase(userInfoCountApi.rejected, (state, action) => {
        state.userInfoCountLoading = false;
        state.userInfoCountData = [];
      })

      // serverInfo List
      .addCase(serverInfoListApi.pending, (state, action) => {
        state.serverInfoListLoading = true;
      })
      .addCase(serverInfoListApi.fulfilled, (state, action) => {
        state.serverInfoListData = action.payload;
        state.serverInfoListLoading = false;
      })
      .addCase(serverInfoListApi.rejected, (state, action) => {
        state.serverInfoListLoading = false;
        state.serverInfoListData = [];
      })

      .addCase(serverInfoCountApi.pending, (state, action) => {
        state.serverInfoCountLoading = true;
      })
      .addCase(serverInfoCountApi.fulfilled, (state, action) => {
        state.serverInfoCountData = action.payload;
        state.serverInfoCountLoading = false;
      })
      .addCase(serverInfoCountApi.rejected, (state, action) => {
        state.serverInfoCountLoading = false;
        state.serverInfoCountData = [];
      })

      // cloudAccount List
      .addCase(cloudAccountListApi.pending, (state, action) => {
        state.cloudAccountListLoading = true;
      })
      .addCase(cloudAccountListApi.fulfilled, (state, action) => {
        state.cloudAccountListData = action.payload;
        state.cloudAccountListLoading = false;
      })
      .addCase(cloudAccountListApi.rejected, (state, action) => {
        state.cloudAccountListLoading = false;
        state.cloudAccountListData = [];
      })

      .addCase(cloudAccountCountApi.pending, (state, action) => {
        state.cloudAccountCountLoading = true;
      })
      .addCase(cloudAccountCountApi.fulfilled, (state, action) => {
        state.cloudAccountCountData = action.payload;
        state.cloudAccountCountLoading = false;
      })
      .addCase(cloudAccountCountApi.rejected, (state, action) => {
        state.cloudAccountCountLoading = false;
        state.cloudAccountCountData = [];
      })
      // osLicenseConfig List
      .addCase(osLicenseConfigListApi.pending, (state, action) => {
        state.osLicenseConfigListLoading = true;
      })
      .addCase(osLicenseConfigListApi.fulfilled, (state, action) => {
        state.osLicenseConfigListData = action.payload;
        state.osLicenseConfigListLoading = false;
      })
      .addCase(osLicenseConfigListApi.rejected, (state, action) => {
        state.osLicenseConfigListLoading = false;
        state.osLicenseConfigListData = [];
      })

      .addCase(osLicenseConfigCountApi.pending, (state, action) => {
        state.osLicenseConfigCountLoading = true;
      })
      .addCase(osLicenseConfigCountApi.fulfilled, (state, action) => {
        state.osLicenseConfigCountData = action.payload;
        state.osLicenseConfigCountLoading = false;
      })
      .addCase(osLicenseConfigCountApi.rejected, (state, action) => {
        state.osLicenseConfigCountLoading = false;
        state.osLicenseConfigCountData = [];
      })

      .addCase(isLdapUserCheckAPI.pending, (state, action) => {
        state.isLdapUserLoading = true;
      })
      .addCase(isLdapUserCheckAPI.fulfilled, (state, action) => {
        state.isLdapUser = action.payload;
        state.isLdapUserLoading = false;
      })
      .addCase(isLdapUserCheckAPI.rejected, (state, action) => {
        state.isLdapUserLoading = false;
        state.isLdapUser = [];
      })

      .addCase(ldapSyncAPI.pending, (state, action) => {
        state.ldapSyncLoading = true;
      })
      .addCase(ldapSyncAPI.fulfilled, (state, action) => {
        state.ldapSyncdata = action.payload;
        state.ldapSyncdataFulFill = true;
        state.ldapSyncLoading = false;
      })
      .addCase(ldapSyncAPI.rejected, (state, action) => {
        state.ldapSyncLoading = false;
        state.ldapSyncdata = [];
      })


      .addCase(userSearchList.pending, (state, action) => {
        state.userSearchListLoading = true;
      })
      .addCase(userSearchList.fulfilled, (state, action) => {
        state.userSearchListData = action.payload;
        state.userSearchListLoading = false;
      })
      .addCase(userSearchList.rejected, (state, action) => {
        state.userSearchListLoading = false;
        state.userSearchListData = [];
      })

      .addCase(saveReviewerConfiguration.pending, (state, action) => {
        state.saveReviewerConfigurationLoading = true;
      })
      .addCase(saveReviewerConfiguration.fulfilled, (state, action) => {
        state.saveReviewerConfigurationData = action.payload;
        state.saveReviewerConfigurationLoading = false;
      })
      .addCase(saveReviewerConfiguration.rejected, (state, action) => {
        state.saveReviewerConfigurationLoading = false;
        state.saveReviewerConfigurationData = [];
      })

      .addCase(reviewersList.pending, (state, action) => {
        state.reviewersListLoading = true;
      })
      .addCase(reviewersList.fulfilled, (state, action) => {
        state.reviewersListData = action.payload;
        state.reviewersListLoading = false;
      })
      .addCase(reviewersList.rejected, (state, action) => {
        state.reviewersListLoading = false;
        state.reviewersListData = [];
      })

      .addCase(reviewConfiguration.pending, (state, action) => {
        state.reviewConfigurationLoading = true;
      })
      .addCase(reviewConfiguration.fulfilled, (state, action) => {
        state.reviewConfigurationData = action.payload;
        state.reviewConfigurationLoading = false;
      })
      .addCase(reviewConfiguration.rejected, (state, action) => {
        state.reviewConfigurationLoading = false;
        state.reviewConfigurationData = [];
      })

  },
});

export const { setCmdbId, resetDataSave, resetresponseData, resetldapSyncStatus } =
  CMDBSlice.actions;
export default CMDBSlice.reducer;
