import React from "react";
import {Button} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
const ButtonComponent = (props) => {
    const btnClass = props.class ? props.class : "btnStyle";

    return (
        <Button className={btnClass} onClick={props.onClick} {...props}>{props.isloading ? <Spinner/> :props.text  }</Button>
    )
}

export default ButtonComponent;