import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../app/utilities/helpers";

const cookies = new Cookies();

export const LoginTrendApi = createAsyncThunk(
  "post/LoginTrendApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/g&c/activeUserCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

export const ActiveUsersLast = createAsyncThunk(
  "post/ActiveUsersLast",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getCountofActiveUsers`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

export const CurrentDayLoggedUsersPerToolsApi = createAsyncThunk(
  "post/CurrentDayLoggedUsersPerTools",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/currentDayLoggedUserStatusForTodayTools`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const BitbucketUserDetailsListAPI = createAsyncThunk(
  "post/BitbucketUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/bitbucketLoggedUserDetails/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const BitbucketUserDetailsCountAPI = createAsyncThunk(
  "post/BitbucketUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageCountForBitbucketLoggedUserDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
//Jenkins User Details
export const JenkinsUserDetailsListAPI = createAsyncThunk(
  "post/JenkinsUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/jenkinsLoggedUserDetails/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JenkinsUserDetailsCountAPI = createAsyncThunk(
  "post/JenkinsUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageCountForJenkinsLoggedUserDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JiraUserDetailsListAPI = createAsyncThunk(
  "post/JiraUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/jiraLoggedUserDetails/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JiraUserDetailsCountAPI = createAsyncThunk(
  "post/JiraUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageCountForJiraLoggedUserDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const ConfluenceUserDetailsListAPI = createAsyncThunk(
  "post/ConfluenceUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/confluenceLoggedUserDetails/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const ConfluenceUserDetailsCountAPI = createAsyncThunk(
  "post/ConfluenceUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageForConfluenceLoggedUserDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

// Active users
export const BitbucketActiveUserDetailsListAPI = createAsyncThunk(
  "post/BitbucketActiveUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/currentDayBitbucketLoggedUserDetailsWithSearchAndPagination/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const BitbucketActiveUserDetailsCountAPI = createAsyncThunk(
  "post/BitbucketActiveUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageForCurrentDayBitbucketLoggedUserDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JenkinsActiveUserDetailsListAPI = createAsyncThunk(
  "post/JenkinsActiveUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/currentDayJenkinsLoggedUserDetailsWithPagination/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JenkinsActiveUserDetailsCountAPI = createAsyncThunk(
  "post/JenkinsActiveUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageCountCurrentDayJenkins`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JiraActiveUserDetailsListAPI = createAsyncThunk(
  "post/JiraActiveUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/currentDayJiraLoggedUserDetailsWithSearchAndPagination/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JiraActiveUserDetailsCountAPI = createAsyncThunk(
  "post/JiraActiveUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageForCurrentDayJiraLoggedUserDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

export const JiraInActiveUserDetailsListAPI = createAsyncThunk(
  "post/JiraInActiveUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/jiraInactiveUsersList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JiraInActiveUserDetailsCountAPI = createAsyncThunk(
  "post/JiraInActiveUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/jiraInactiveUserKeywordUsage`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const ConfluenceActiveUserDetailsListAPI = createAsyncThunk(
  "post/ConfluenceActiveUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/currentDayConfluenceLoggedUserDetailsWithSearchAndPagination/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const ConfluenceActiveUserDetailsCountAPI = createAsyncThunk(
  "post/ConfluenceActiveUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageForCurrentDayConfluenceLoggedUserDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const ConfluenceInActiveUserDetailsListAPI = createAsyncThunk(
  "post/ConfluenceInActiveUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/confluenceInactiveUsersList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const ConfluenceInActiveUserDetailsCountAPI = createAsyncThunk(
  "post/ConfluenceInActiveUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/confluenceInactiveUsersKeywordUsage`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
// InActive users
export const BitbucketInActiveUserDetailsListAPI = createAsyncThunk(
  "post/BitbucketInActiveUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/bitBucketInactiveUsersList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const BitbucketInActiveUserDetailsCountAPI = createAsyncThunk(
  "post/BitbucketInActiveUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/bitBucketInactiveUsersKeywordUsage`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JenkinsInActiveUserDetailsListAPI = createAsyncThunk(
  "post/JenkinsInActiveUserDetailsListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `/api/v1/g&c/jenkinsInactiveUsersList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
export const JenkinsInActiveUserDetailsCountAPI = createAsyncThunk(
  "post/JenkinsInActiveUserDetailsCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/g&c/getKeywordUsageCountJenkinsInactive`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
const initialState = {
  LoginTrendData: [],
  ActiveUsersLastData: [],
  LoginTrendloading: false,
  ActiveUsersLastLoading: false,
  BitbucketUserDetailsList: [],
  BitbucketUserDetailsListLoading: false,
  JenkinsUserDetailsList: [],
  JenkinsUserDetailsListLoading: false,
  CurrentDayToolsLoggedUsersLoading: false,
  CurrentDayToolsLoggedUsers: [],
  BitbucketUserDetailsCount: [],
  BitbucketUserDetailsCountLoading: false,
  JenkinsUserDetailsCount: [],
  JenkinsUserDetailsCountLoading: false,
  JiraUserDetailsList: [],
  JiraUserDetailsListLoading: false,
  JiraUserDetailsCount: [],
  JiraUserDetailsCountLoading: false,
  ConfluenceUserDetailsList: [],
  ConfluenceUserDetailsListLoading: false,
  ConfluenceUserDetailsCount: [],
  ConfluenceUserDetailsCountLoading: false,
  // Active users 
  BitbucketActiveUserDetailsList: [],
  BitbucketActiveUserDetailsListLoading: false,
  BitbucketActiveUserDetailsCount: [],
  BitbucketActiveUserDetailsCountLoading: false,
  JenkinsActiveUserDetailsList: [],
  JenkinsActiveUserDetailsListLoading: false,
  JenkinsActiveUserDetailsCount: [],
  JenkinsActiveUserDetailsCountLoading: false,
  JiraActiveUserDetailsList: [],
  JiraActiveUserDetailsListLoading: false,
  JiraActiveUserDetailsCount: [],
  JiraActiveUserDetailsCountLoading: false,
  ConfluenceActiveUserDetailsList: [],
  ConfluenceActiveUserDetailsListLoading: false,
  ConfluenceActiveUserDetailsCount: [],
  ConfluenceActiveUserDetailsCountLoading: false,
  // InActive list
  JiraInActiveUserDetailsList: [],
  JiraInActiveUserDetailsListLoading: false,
  JiraInActiveUserDetailsCount: [],
  JiraInActiveUserDetailsCountLoading: false,
  ConfluenceInActiveUserDetailsList: [],
  ConfluenceInActiveUserDetailsListLoading: false,
  ConfluenceInActiveUserDetailsCount: [],
  ConfluenceInActiveUserDetailsCountLoading: false,
  BitbucketInActiveUserDetailsList: [],
  BitbucketInActiveUserDetailsListLoading: false,
  BitbucketInActiveUserDetailsCount: [],
  BitbucketInActiveUserDetailsCountLoading: false,
  JenkinsInActiveUserDetailsList: [],
  JenkinsInActiveUserDetailsListLoading: false,
  JenkinsInActiveUserDetailsCount: [],
  JenkinsInActiveUserDetailsCountLoading: false,
};

export const LicenseSlice = createSlice({
  name: "LicenseSlice",
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(LoginTrendApi.pending, (state, action) => {
        state.LoginTrendloading = true;
      })
      .addCase(LoginTrendApi.fulfilled, (state, action) => {
        state.LoginTrendData = action.payload;
        state.LoginTrendloading = false;
      })
      .addCase(LoginTrendApi.rejected, (state, action) => {
        state.LoginTrendloading = false;
        state.LoginTrendData = [];
      })

      .addCase(ActiveUsersLast.pending, (state, action) => {
        state.ActiveUsersLastLoading = true;
      })
      .addCase(ActiveUsersLast.fulfilled, (state, action) => {
        state.ActiveUsersLastData = action.payload;
        state.ActiveUsersLastLoading = false;
      })
      .addCase(ActiveUsersLast.rejected, (state, action) => {
        state.ActiveUsersLastLoading = false;
        state.ActiveUsersLastData = [];
      })

      .addCase(CurrentDayLoggedUsersPerToolsApi.pending, (state, action) => {
        state.CurrentDayToolsLoggedUsersLoading = true;
      })
      .addCase(CurrentDayLoggedUsersPerToolsApi.fulfilled, (state, action) => {
        state.CurrentDayToolsLoggedUsers = action.payload;
        state.CurrentDayToolsLoggedUsersLoading = false;
      })
      .addCase(CurrentDayLoggedUsersPerToolsApi.rejected, (state, action) => {
        state.CurrentDayToolsLoggedUsersLoading = false;
      })

      // Bitbucket users details
      .addCase(BitbucketUserDetailsListAPI.pending, (state, action) => {
        state.BitbucketUserDetailsListLoading = true;
      })
      .addCase(BitbucketUserDetailsListAPI.fulfilled, (state, action) => {
        state.BitbucketUserDetailsList = action.payload;
        state.BitbucketUserDetailsListLoading = false;
      })
      .addCase(BitbucketUserDetailsListAPI.rejected, (state, action) => {
        state.BitbucketUserDetailsListLoading = false;
        state.BitbucketUserDetailsList = [];
      })

      .addCase(BitbucketUserDetailsCountAPI.pending, (state, action) => {
        state.BitbucketUserDetailsCountLoading = true;
      })
      .addCase(BitbucketUserDetailsCountAPI.fulfilled, (state, action) => {
        state.BitbucketUserDetailsCount = action.payload;
        state.BitbucketUserDetailsCountLoading = false;
      })
      .addCase(BitbucketUserDetailsCountAPI.rejected, (state, action) => {
        state.BitbucketUserDetailsCountLoading = false;
      })
      // Jenkins users details
      .addCase(JenkinsUserDetailsListAPI.pending, (state, action) => {
        state.JenkinsUserDetailsListLoading = true;
      })
      .addCase(JenkinsUserDetailsListAPI.fulfilled, (state, action) => {
        state.JenkinsUserDetailsList = action.payload;
        state.JenkinsUserDetailsListLoading = false;
      })
      .addCase(JenkinsUserDetailsListAPI.rejected, (state, action) => {
        state.JenkinsUserDetailsListLoading = false;
        state.JenkinsUserDetailsList = [];
      })

      .addCase(JenkinsUserDetailsCountAPI.pending, (state, action) => {
        state.JenkinsUserDetailsCountLoading = true;
      })
      .addCase(JenkinsUserDetailsCountAPI.fulfilled, (state, action) => {
        state.JenkinsUserDetailsCount = action.payload;
        state.JenkinsUserDetailsCountLoading = false;
      })
      .addCase(JenkinsUserDetailsCountAPI.rejected, (state, action) => {
        state.JenkinsUserDetailsCountLoading = false;
      })


      // Jira user details
      .addCase(JiraUserDetailsListAPI.pending, (state, action) => {
        state.JiraUserDetailsListLoading = true;
      })
      .addCase(JiraUserDetailsListAPI.fulfilled, (state, action) => {
        state.JiraUserDetailsList = action.payload;
        state.JiraUserDetailsListLoading = false;
      })
      .addCase(JiraUserDetailsListAPI.rejected, (state, action) => {
        state.JiraUserDetailsListLoading = false;
        state.JiraUserDetailsList = [];
      })

      .addCase(JiraUserDetailsCountAPI.pending, (state, action) => {
        state.JiraUserDetailsCountLoading = true;
      })
      .addCase(JiraUserDetailsCountAPI.fulfilled, (state, action) => {
        state.JiraUserDetailsCount = action.payload;
        state.JiraUserDetailsCountLoading = false;
      })
      .addCase(JiraUserDetailsCountAPI.rejected, (state, action) => {
        state.JiraUserDetailsCountLoading = false;
      })
      // Confluence user details
      .addCase(ConfluenceUserDetailsListAPI.pending, (state, action) => {
        state.ConfluenceUserDetailsListLoading = true;
      })
      .addCase(ConfluenceUserDetailsListAPI.fulfilled, (state, action) => {
        state.ConfluenceUserDetailsList = action.payload;
        state.ConfluenceUserDetailsListLoading = false;
      })
      .addCase(ConfluenceUserDetailsListAPI.rejected, (state, action) => {
        state.ConfluenceUserDetailsListLoading = false;
        state.ConfluenceUserDetailsList = [];
      })

      .addCase(ConfluenceUserDetailsCountAPI.pending, (state, action) => {
        state.ConfluenceUserDetailsCountLoading = true;
      })
      .addCase(ConfluenceUserDetailsCountAPI.fulfilled, (state, action) => {
        state.ConfluenceUserDetailsCount = action.payload;
        state.ConfluenceUserDetailsCountLoading = false;
      })
      .addCase(ConfluenceUserDetailsCountAPI.rejected, (state, action) => {
        state.ConfluenceUserDetailsCountLoading = false;
      })

      // Bitbucket Active users details
      .addCase(BitbucketActiveUserDetailsListAPI.pending, (state, action) => {
        state.BitbucketActiveUserDetailsListLoading = true;
      })
      .addCase(BitbucketActiveUserDetailsListAPI.fulfilled, (state, action) => {
        state.BitbucketActiveUserDetailsList = action.payload;
        state.BitbucketActiveUserDetailsListLoading = false;
      })
      .addCase(BitbucketActiveUserDetailsListAPI.rejected, (state, action) => {
        state.BitbucketActiveUserDetailsListLoading = false;
        state.BitbucketActiveUserDetailsList = [];
      })

      .addCase(BitbucketActiveUserDetailsCountAPI.pending, (state, action) => {
        state.BitbucketActiveUserDetailsCountLoading = true;
      })
      .addCase(BitbucketActiveUserDetailsCountAPI.fulfilled, (state, action) => {
        state.BitbucketActiveUserDetailsCount = action.payload;
        state.BitbucketActiveUserDetailsCountLoading = false;
      })
      .addCase(BitbucketActiveUserDetailsCountAPI.rejected, (state, action) => {
        state.BitbucketActiveUserDetailsCountLoading = false;
      })
      // Jenkins Active users details
      .addCase(JenkinsActiveUserDetailsListAPI.pending, (state, action) => {
        state.JenkinsActiveUserDetailsListLoading = true;
      })
      .addCase(JenkinsActiveUserDetailsListAPI.fulfilled, (state, action) => {
        state.JenkinsActiveUserDetailsList = action.payload;
        state.JenkinsActiveUserDetailsListLoading = false;
      })
      .addCase(JenkinsActiveUserDetailsListAPI.rejected, (state, action) => {
        state.JenkinsActiveUserDetailsListLoading = false;
        state.JenkinsActiveUserDetailsList = [];
      })

      .addCase(JenkinsActiveUserDetailsCountAPI.pending, (state, action) => {
        state.JenkinsActiveUserDetailsCountLoading = true;
      })
      .addCase(JenkinsActiveUserDetailsCountAPI.fulfilled, (state, action) => {
        state.JenkinsActiveUserDetailsCount = action.payload;
        state.JenkinsActiveUserDetailsCountLoading = false;
      })
      .addCase(JenkinsActiveUserDetailsCountAPI.rejected, (state, action) => {
        state.JenkinsActiveUserDetailsCountLoading = false;
      })


      // Jira Active user details
      .addCase(JiraActiveUserDetailsListAPI.pending, (state, action) => {
        state.JiraActiveUserDetailsListLoading = true;
      })
      .addCase(JiraActiveUserDetailsListAPI.fulfilled, (state, action) => {
        state.JiraActiveUserDetailsList = action.payload;
        state.JiraActiveUserDetailsListLoading = false;
      })
      .addCase(JiraActiveUserDetailsListAPI.rejected, (state, action) => {
        state.JiraActiveUserDetailsListLoading = false;
        state.JiraActiveUserDetailsList = [];
      })

      .addCase(JiraActiveUserDetailsCountAPI.pending, (state, action) => {
        state.JiraActiveUserDetailsCountLoading = true;
      })
      .addCase(JiraActiveUserDetailsCountAPI.fulfilled, (state, action) => {
        state.JiraActiveUserDetailsCount = action.payload;
        state.JiraActiveUserDetailsCountLoading = false;
      })
      .addCase(JiraActiveUserDetailsCountAPI.rejected, (state, action) => {
        state.JiraActiveUserDetailsCountLoading = false;
      })
      // Confluence Active user details
      .addCase(ConfluenceActiveUserDetailsListAPI.pending, (state, action) => {
        state.ConfluenceActiveUserDetailsListLoading = true;
      })
      .addCase(ConfluenceActiveUserDetailsListAPI.fulfilled, (state, action) => {
        state.ConfluenceActiveUserDetailsList = action.payload;
        state.ConfluenceActiveUserDetailsListLoading = false;
      })
      .addCase(ConfluenceActiveUserDetailsListAPI.rejected, (state, action) => {
        state.ConfluenceActiveUserDetailsListLoading = false;
        state.ConfluenceActiveUserDetailsList = [];
      })

      .addCase(ConfluenceActiveUserDetailsCountAPI.pending, (state, action) => {
        state.ConfluenceActiveUserDetailsCountLoading = true;
      })
      .addCase(ConfluenceActiveUserDetailsCountAPI.fulfilled, (state, action) => {
        state.ConfluenceActiveUserDetailsCount = action.payload;
        state.ConfluenceActiveUserDetailsCountLoading = false;
      })
      .addCase(ConfluenceActiveUserDetailsCountAPI.rejected, (state, action) => {
        state.ConfluenceActiveUserDetailsCountLoading = false;
      })

      // Jira InActive user details
      .addCase(JiraInActiveUserDetailsListAPI.pending, (state, action) => {
        state.JiraInActiveUserDetailsListLoading = true;
      })
      .addCase(JiraInActiveUserDetailsListAPI.fulfilled, (state, action) => {
        state.JiraInActiveUserDetailsList = action.payload;
        state.JiraInActiveUserDetailsListLoading = false;
      })
      .addCase(JiraInActiveUserDetailsListAPI.rejected, (state, action) => {
        state.JiraInActiveUserDetailsListLoading = false;
        state.JiraInActiveUserDetailsList = [];
      })

      .addCase(JiraInActiveUserDetailsCountAPI.pending, (state, action) => {
        state.JiraInActiveUserDetailsCountLoading = true;
      })
      .addCase(JiraInActiveUserDetailsCountAPI.fulfilled, (state, action) => {
        state.JiraInActiveUserDetailsCount = action.payload;
        state.JiraInActiveUserDetailsCountLoading = false;
      })
      .addCase(JiraInActiveUserDetailsCountAPI.rejected, (state, action) => {
        state.JiraInActiveUserDetailsCountLoading = false;
      })

      // Confluence InActive user details
      .addCase(ConfluenceInActiveUserDetailsListAPI.pending, (state, action) => {
        state.ConfluenceInActiveUserDetailsListLoading = true;
      })
      .addCase(ConfluenceInActiveUserDetailsListAPI.fulfilled, (state, action) => {
        state.ConfluenceInActiveUserDetailsList = action.payload;
        state.ConfluenceInActiveUserDetailsListLoading = false;
      })
      .addCase(ConfluenceInActiveUserDetailsListAPI.rejected, (state, action) => {
        state.ConfluenceInActiveUserDetailsListLoading = false;
        state.ConfluenceInActiveUserDetailsList = [];
      })

      .addCase(ConfluenceInActiveUserDetailsCountAPI.pending, (state, action) => {
        state.ConfluenceInActiveUserDetailsCountLoading = true;
      })
      .addCase(ConfluenceInActiveUserDetailsCountAPI.fulfilled, (state, action) => {
        state.ConfluenceInActiveUserDetailsCount = action.payload;
        state.ConfluenceInActiveUserDetailsCountLoading = false;
      })
      .addCase(ConfluenceInActiveUserDetailsCountAPI.rejected, (state, action) => {
        state.ConfluenceInActiveUserDetailsCountLoading = false;
      })

      // Bitbucket InActive users details
      .addCase(BitbucketInActiveUserDetailsListAPI.pending, (state, action) => {
        state.BitbucketInActiveUserDetailsListLoading = true;
      })
      .addCase(BitbucketInActiveUserDetailsListAPI.fulfilled, (state, action) => {
        state.BitbucketInActiveUserDetailsList = action.payload;
        state.BitbucketInActiveUserDetailsListLoading = false;
      })
      .addCase(BitbucketInActiveUserDetailsListAPI.rejected, (state, action) => {
        state.BitbucketInActiveUserDetailsListLoading = false;
        state.BitbucketInActiveUserDetailsList = [];
      })

      .addCase(BitbucketInActiveUserDetailsCountAPI.pending, (state, action) => {
        state.BitbucketInActiveUserDetailsCountLoading = true;
      })
      .addCase(BitbucketInActiveUserDetailsCountAPI.fulfilled, (state, action) => {
        state.BitbucketInActiveUserDetailsCount = action.payload;
        state.BitbucketInActiveUserDetailsCountLoading = false;
      })
      .addCase(BitbucketInActiveUserDetailsCountAPI.rejected, (state, action) => {
        state.BitbucketInActiveUserDetailsCountLoading = false;
      })
      // Jenkins InActive users details
      .addCase(JenkinsInActiveUserDetailsListAPI.pending, (state, action) => {
        state.JenkinsInActiveUserDetailsListLoading = true;
      })
      .addCase(JenkinsInActiveUserDetailsListAPI.fulfilled, (state, action) => {
        state.JenkinsInActiveUserDetailsList = action.payload;
        state.JenkinsInActiveUserDetailsListLoading = false;
      })
      .addCase(JenkinsInActiveUserDetailsListAPI.rejected, (state, action) => {
        state.JenkinsInActiveUserDetailsListLoading = false;
        state.JenkinsInActiveUserDetailsList = [];
      })

      .addCase(JenkinsInActiveUserDetailsCountAPI.pending, (state, action) => {
        state.JenkinsInActiveUserDetailsCountLoading = true;
      })
      .addCase(JenkinsInActiveUserDetailsCountAPI.fulfilled, (state, action) => {
        state.JenkinsInActiveUserDetailsCount = action.payload;
        state.JenkinsInActiveUserDetailsCountLoading = false;
      })
      .addCase(JenkinsInActiveUserDetailsCountAPI.rejected, (state, action) => {
        state.JenkinsInActiveUserDetailsCountLoading = false;
      })

  },
});
export default LicenseSlice.reducer;
