import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";

const cookies = new Cookies();

export const graphtDataApi = createAsyncThunk(
  "post/GraphData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName } = props;
      const response = await fetch(
        `/api/v1/conformancePack/TRMG/GraphData/${packName}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getCountForNonComplianceAPI = createAsyncThunk(
  "post/getCountForNonComplianceAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName , accountId } = props;
      const response = await fetch(
        `/api/v1/conformancePack/TRMG/getCountForNonCompliance/${packName}/${accountId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getListForNonComplianceAPI = createAsyncThunk(
  "post/getListForNonComplianceAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName , accountId ,pageNumber ,itemsPerPage} = props;
      const response = await fetch(
        `/api/v1/conformancePack/TRMG/getSearchNonCompliancesRules/list/${packName}/${accountId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getCompliantListAPI = createAsyncThunk(
  "post/getCompliantListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName , accountId ,pageNumber ,itemsPerPage} = props;
      const response = await fetch(
        `/api/v1/conformancePack/TRMG/getSearchCompliancesRules/list/${packName}/${accountId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getCompliantCountAPI = createAsyncThunk(
  "post/getCompliantCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName , accountId } = props;
      const response = await fetch(
        `/api/v1/conformancePack/TRMG/getCountForTrmgCompliance/${packName}/${accountId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getNotApplicableListAPI = createAsyncThunk(
  "post/getNotApplicableListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName , accountId ,pageNumber ,itemsPerPage} = props;
      const response = await fetch(
        `/api/v1/conformancePack/TRMG/GetNotApplicable/list/${packName}/${accountId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getNotApplicableCountAPI = createAsyncThunk(
  "post/getNotApplicableCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName , accountId } = props;
      const response = await fetch(
        `/api/v1/conformancePack/TRMG/getCountForNotApplicable/${packName}/${accountId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


const initialState = {
  graphtData: [],
  graphtDataLoading: false,
  getCountForNonComplianceData:[],
  getCountForNonComplianceLoading:false,
  getListForNonComplianceData:[],
  getListForNonComplianceLoading:false,
  getCompliantListData:[],
  getCompliantListLoading:false,
  getCompliantCountData:[],
  getCompliantCountLoading:false,
  getNotApplicableListData:[],
  getNotApplicableListLoading:false,
  getNotApplicableCountData:[],
  getNotApplicableCountLoading:false,
  error: "",
}

export const Circular9Slice = createSlice({
  name: 'circular9Slice',
  initialState,

  reducers: {
    resetCircular9Data: () => {
      return initialState;
    }
  }, extraReducers: (builder) => {
    builder
      // C9 chart
      .addCase(graphtDataApi.pending, (state, action) => {
        state.graphtDataLoading = true;
      })
      .addCase(graphtDataApi.fulfilled, (state, action) => {
        state.graphtData = action.payload;
        state.graphtDataLoading = false;
      })
      .addCase(graphtDataApi.rejected, (state, action) => {
        state.graphtDataLoading = false;
      })

      // getCountForNonComplianceAPI
      .addCase(getCountForNonComplianceAPI.pending, (state, action) => {
        state.getCountForNonComplianceLoading = true;
      })
      .addCase(getCountForNonComplianceAPI.fulfilled, (state, action) => {
        state.getCountForNonComplianceData = action.payload;
        state.getCountForNonComplianceLoading = false;
      })
      .addCase(getCountForNonComplianceAPI.rejected, (state, action) => {
        state.getCountForNonComplianceLoading = false;
      })

      // getListForNonComplianceAPI
      .addCase(getListForNonComplianceAPI.pending, (state, action) => {
        state.getListForNonComplianceLoading = true;
      })
      .addCase(getListForNonComplianceAPI.fulfilled, (state, action) => {
        state.getListForNonComplianceData = action.payload;
        state.getListForNonComplianceLoading = false;
      })
      .addCase(getListForNonComplianceAPI.rejected, (state, action) => {
        state.getListForNonComplianceLoading = false;
      })

      // getCompliantListAPI
      .addCase(getCompliantListAPI.pending, (state, action) => {
        state.getCompliantListLoading = true;
      })
      .addCase(getCompliantListAPI.fulfilled, (state, action) => {
        state.getCompliantListData = action.payload;
        state.getCompliantListLoading = false;
      })
      .addCase(getCompliantListAPI.rejected, (state, action) => {
        state.getCompliantListLoading = false;
      })

      // getCompliantCountAPI
      .addCase(getCompliantCountAPI.pending, (state, action) => {
        state.getCompliantCountLoading = true;
      })
      .addCase(getCompliantCountAPI.fulfilled, (state, action) => {
        state.getCompliantCountData = action.payload;
        state.getCompliantCountLoading = false;
      })
      .addCase(getCompliantCountAPI.rejected, (state, action) => {
        state.getCompliantCountLoading = false;
      })

      // getNotApplicableListAPI
      .addCase(getNotApplicableListAPI.pending, (state, action) => {
        state.getNotApplicableListLoading = true;
      })
      .addCase(getNotApplicableListAPI.fulfilled, (state, action) => {
        state.getNotApplicableListData = action.payload;
        state.getNotApplicableListLoading = false;
      })
      .addCase(getNotApplicableListAPI.rejected, (state, action) => {
        state.getNotApplicableListLoading = false;
      })

      // getNotApplicableCountAPI
      .addCase(getNotApplicableCountAPI.pending, (state, action) => {
        state.getNotApplicableCountLoading = true;
      })
      .addCase(getNotApplicableCountAPI.fulfilled, (state, action) => {
        state.getNotApplicableCountData = action.payload;
        state.getNotApplicableCountLoading = false;
      })
      .addCase(getNotApplicableCountAPI.rejected, (state, action) => {
        state.getNotApplicableCountLoading = false;
      });

  },
});

export const { resetCircular9Data } = Circular9Slice.actions;
export default Circular9Slice.reducer;