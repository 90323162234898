import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const AccessDenied = () => {
  const { loggedUser } = useSelector((state) => state.common);
  const { userRole } = loggedUser;
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      if (userRole == "User") {
        window.history.go(-2); 
      } else {
        window.history.go(-1); 
      }
    };
    sessionStorage.setItem("lastVisitedURL", window.location.pathname);

    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div>
      <div className="content text-center">
        <div className="a-403">403</div>
        <div className="a-forbidden">Forbidden</div>
        <div>Access to this resource on the server is denied!</div>
      </div>
    </div>
  );
};

export default AccessDenied;
