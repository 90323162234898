import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../app/utilities/helpers';

const cookies = new Cookies();

//Grouppopup1
export const GroupTable = createAsyncThunk(
  "post/GroupTable",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsGroupDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Groupcountpopup1
export const GroupTableCount = createAsyncThunk(
  "post/GroupTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsGroupDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Userspopup1
export const UsersTable = createAsyncThunk(
  "post/UsersTable",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsUserDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Userscountpopup1
export const UsersTableCount = createAsyncThunk(
  "post/UsersTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsUserDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//ServiceRolespopup1
export const ServiceRolesTable = createAsyncThunk(
  "post/ServiceRolesTable",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsServiceRolesWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//ServiceRolescountpopup1
export const ServiceRolesTableCount = createAsyncThunk(
  "post/ServiceRolesTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsServiceRolesCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Rolespopup1
export const RolesTable = createAsyncThunk(
  "post/RolesTable",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsRoleDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Rolescountpopup1
export const RolesTableCount = createAsyncThunk(
  "post/RolesTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsRoleDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//GroupsUserspopup2
export const GroupsUsers = createAsyncThunk(
  "post/GroupsUsers",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsGroupUserDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//GroupsUsersCountpopup2
export const GroupsUsersTableCount = createAsyncThunk(
  "post/GroupsUsersTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsGroupUserDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


//AttachedPolicypopup2
export const AttachedPolicy = createAsyncThunk(
  "post/AttachedPolicy",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsGroupPolicyDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//AttachedPolicyCountpopup2
export const AttachedPolicyTableCount = createAsyncThunk(
  "post/AttachedPolicyTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/awsIam/getGroupPolicyDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//InlinePolicypopup2
export const InlinePolicy = createAsyncThunk(
  "post/InlinePolicy",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/awsIam/getAwsGroupInlinePolicyDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//InlinePolicyCountpopup2
export const InlinePolicyTableCount = createAsyncThunk(
  "post/InlinePolicyTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/awsIam/getGroupInlinePolicyDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Remove..Continue
export const continueRemove = createAsyncThunk(
  "post/continueRemove",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload} = props;
      const response = await fetch(
        `/api/v1/awsIam/changeReviewStatus`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });


export const CloudUserTotal = createAsyncThunk(
  "get/Ctotal",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageDD/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)
export const CloudUserPending = createAsyncThunk(
  "get/Cpending",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageDDPending/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)

export const CloudUserContinue = createAsyncThunk(
  "get/CContinue",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageDDContinue/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)
export const CloudUserRemove = createAsyncThunk(
  "get/CRemove",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageDDRemove/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)

export const onpremiseTotal = createAsyncThunk(
  "get/PTotal",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageOnPremiseDD/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)

export const onpremisePending = createAsyncThunk(
  "get/PPending",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDPending/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)

export const onpremiseContinue = createAsyncThunk(
  "get/PContinue",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDContinue/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)

export const onpremiseRemove = createAsyncThunk(
  "get/PRemove",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDRemove/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)
//completed page 
export const CompletedCContinue = createAsyncThunk(
  "get/ComContinue",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewCompletePageDDCloudContinue/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)
export const CompletedCRemove = createAsyncThunk(
  "get/ComRemove",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewCompletePageDDCloudRemove/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)

export const CompletedPermiseContinue = createAsyncThunk(
  "get/ComPerContinue",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewCompletePageOnPremiseDD/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)

export const CompletedPermiseRemove = createAsyncThunk(
  "get/ComPerRemove",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appname } = props;
      const response = await fetch(`/api/v1/UserAccessRest/reviewCompletePageOnPremiseDDRemove/${appname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)
export const ComplianceTrendAPI = createAsyncThunk(
  "post/ComplianceTrendAPI",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`/api/v1/UserAccessRest/CISOGraphOne`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);




export const reviewslice = createSlice({
  name: 'review',
  initialState: {
    CTotalData: [],
    CPendingData: [],
    CContinueData: [],
    CRemovedData: [],
    PTotalData: [],
    PPendingData: [],
    PContinueData: [],
    PRemovedData: [],
    CompltedCContinueData: [],
    CompltedCRemoveData: [],
    CompltedPContinueData: [],
    CompltedPRemoveData: [],
    CompletedLoading: false,
    CTotalloading: false,
    complianceGraphData: [],
    complianceLoading: false,
    UsersTableListData: [],
    UsersTableListDataLoading: false,
    ServiceRolesTableListData: [],
    ServiceRolesTableListDataLoading: false,
    RolesTableListData: [],
    RolesTableListDataLoading: false,
    GroupsUsersListData: [],
    GroupsUsersListDataLoading: false,
    UsersListCountData: [],
    UsersTableListCountLoading: false,
    ServiceRolesListCountData: [],
    ServiceRolesListCountLoading: false,
    RolesListCountData: [],
    RolesListCountLoading: false,
    AttachedPolicyListData: [],
    AttachedPolicyListDataLoading: false,
    InlinePolicyListData: [],
    InlinePolicyListDataLoading: false,
    GroupsUsersListCountData: [],
    GroupsUsersListCountLoading: false,
    AttachedPolicyListCountData: [],
    AttachedPolicyListCountLoading: false,
    InlinePolicyListCountData: [],
    InlinePolicyListCountLoading: false,
    GroupTableListData:[],
    GroupTableListDataLoading:false,
    GroupListCountData:[],
    GroupTableListCountLoading:false,
    continueRemoveAction:[],
    continueRemoveLoading:false,
    error: '',
  },

  reducers: {
    reviewSlicereset: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder

      //Grouppopup1
      .addCase(GroupTable.pending, (state, action) => {
        state.GroupTableListDataLoading = true;
      })
      .addCase(GroupTable.fulfilled, (state, action) => {
        state.GroupTableListData = action.payload;
        state.GroupTableListDataLoading = false;
      })
      .addCase(GroupTable.rejected, (state, action) => {
        state.GroupTableListData = [];
        state.GroupTableListDataLoading = false;
      })

      //Groupcountpopup1
      .addCase(GroupTableCount.pending, (state, action) => {
        state.GroupTableListCountLoading = true;
      })
      .addCase(GroupTableCount.fulfilled, (state, action) => {
        state.GroupListCountData = action.payload;
        state.GroupTableListCountLoading = false;
      })
      .addCase(GroupTableCount.rejected, (state, action) => {
        state.GroupListCountData = [];
        state.GroupTableListCountLoading = false;
      })
      //Userspopup1
      .addCase(UsersTable.pending, (state, action) => {
        state.UsersTableListDataLoading = true;
      })
      .addCase(UsersTable.fulfilled, (state, action) => {
        state.UsersTableListData = action.payload;
        state.UsersTableListDataLoading = false;
      })
      .addCase(UsersTable.rejected, (state, action) => {
        state.UsersTableListData = [];
        state.UsersTableListDataLoading = false;
      })

      //Userscountpopup1
      .addCase(UsersTableCount.pending, (state, action) => {
        state.UsersTableListCountLoading = true;
      })
      .addCase(UsersTableCount.fulfilled, (state, action) => {
        state.UsersListCountData = action.payload;
        state.UsersTableListCountLoading = false;
      })
      .addCase(UsersTableCount.rejected, (state, action) => {
        state.UsersListCountData = [];
        state.UsersTableListCountLoading = false;
      })

      //ServiceRolespopup1
      .addCase(ServiceRolesTable.pending, (state, action) => {
        state.ServiceRolesTableListDataLoading = true;
      })
      .addCase(ServiceRolesTable.fulfilled, (state, action) => {
        state.ServiceRolesTableListData = action.payload;
        state.ServiceRolesTableListDataLoading = false;
      })
      .addCase(ServiceRolesTable.rejected, (state, action) => {
        state.ServiceRolesTableListData = [];
        state.ServiceRolesTableListDataLoading = false;
      })

      //ServiceRolescountpopup1
      .addCase(ServiceRolesTableCount.pending, (state, action) => {
        state.ServiceRolesListCountLoading = true;
      })
      .addCase(ServiceRolesTableCount.fulfilled, (state, action) => {
        state.ServiceRolesListCountData = action.payload;
        state.ServiceRolesListCountLoading = false;
      })
      .addCase(ServiceRolesTableCount.rejected, (state, action) => {
        state.ServiceRolesListCountData = [];
        state.ServiceRolesListCountLoading = false;
      })

      //Rolespopup1
      .addCase(RolesTable.pending, (state, action) => {
        state.RolesTableListDataLoading = true;
      })
      .addCase(RolesTable.fulfilled, (state, action) => {
        state.RolesTableListData = action.payload;
        state.RolesTableListDataLoading = false;
      })
      .addCase(RolesTable.rejected, (state, action) => {
        state.RolesTableListData = [];
        state.RolesTableListDataLoading = false;
      })

      //Rolescountpopup1
      .addCase(RolesTableCount.pending, (state, action) => {
        state.RolesListCountLoading = true;
      })
      .addCase(RolesTableCount.fulfilled, (state, action) => {
        state.RolesListCountData = action.payload;
        state.RolesListCountLoading = false;
      })
      .addCase(RolesTableCount.rejected, (state, action) => {
        state.RolesListCountData = [];
        state.RolesListCountLoading = false;
      })

      //GroupsUsers
      .addCase(GroupsUsers.pending, (state, action) => {
        state.GroupsUsersListDataLoading = true;
      })
      .addCase(GroupsUsers.fulfilled, (state, action) => {
        state.GroupsUsersListData = action.payload;
        state.GroupsUsersListDataLoading = false;
      })
      .addCase(GroupsUsers.rejected, (state, action) => {
        state.GroupsUsersListData = [];
        state.GroupsUsersListDataLoading = false;
      })


      //GroupsUsersCountpopup2
      .addCase(GroupsUsersTableCount.pending, (state, action) => {
        state.GroupsUsersListCountLoading = true;
      })
      .addCase(GroupsUsersTableCount.fulfilled, (state, action) => {
        state.GroupsUsersListCountData = action.payload;
        state.GroupsUsersListCountLoading = false;
      })
      .addCase(GroupsUsersTableCount.rejected, (state, action) => {
        state.GroupsUsersListCountData = [];
        state.GroupsUsersListCountLoading = false;
      })

      //AttachedPolicypopup2
      .addCase(AttachedPolicy.pending, (state, action) => {
        state.AttachedPolicyListDataLoading = true;
      })
      .addCase(AttachedPolicy.fulfilled, (state, action) => {
        state.AttachedPolicyListData = action.payload;
        state.AttachedPolicyListDataLoading = false;
      })
      .addCase(AttachedPolicy.rejected, (state, action) => {
        state.AttachedPolicyListData = [];
        state.AttachedPolicyListDataLoading = false;
      })

      //AttachedPolicyCountpopup2
      .addCase(AttachedPolicyTableCount.pending, (state, action) => {
        state.AttachedPolicyListCountLoading = true;
      })
      .addCase(AttachedPolicyTableCount.fulfilled, (state, action) => {
        state.AttachedPolicyListCountData = action.payload;
        state.AttachedPolicyListCountLoading = false;
      })
      .addCase(AttachedPolicyTableCount.rejected, (state, action) => {
        state.AttachedPolicyListCountData = [];
        state.AttachedPolicyListCountLoading = false;
      })

      //InlinePolicypopup2
      .addCase(InlinePolicy.pending, (state, action) => {
        state.InlinePolicyListDataLoading = true;
      })
      .addCase(InlinePolicy.fulfilled, (state, action) => {
        state.InlinePolicyListData = action.payload;
        state.InlinePolicyListDataLoading = false;
      })
      .addCase(InlinePolicy.rejected, (state, action) => {
        state.InlinePolicyListData = [];
        state.InlinePolicyListDataLoading = false;
      })


      //InlinePolicyCountpopup2
      .addCase(InlinePolicyTableCount.pending, (state, action) => {
        state.InlinePolicyListCountLoading = true;
      })
      .addCase(InlinePolicyTableCount.fulfilled, (state, action) => {
        state.InlinePolicyListCountData = action.payload;
        state.InlinePolicyListCountLoading = false;
      })
      .addCase(InlinePolicyTableCount.rejected, (state, action) => {
        state.InlinePolicyListCountData = [];
        state.InlinePolicyListCountLoading = false;
      })

      //Remove..Continue
      .addCase(continueRemove.pending, (state, action) => {
        //state.TopicDataSave = "";
        state.continueRemoveLoading = true;
      })
      .addCase(continueRemove.fulfilled, (state, action) => {
        state.continueRemoveAction = action.payload;
        state.continueRemoveLoading = false;
      })
      .addCase(continueRemove.rejected, (state, action) => {
        state.continueRemoveLoading = false;
        //state.TopicDataSave = "";
        state.TopicerrorSave = "Something went wrong";
      })

      //CTtoal
      .addCase(CloudUserTotal.pending, (state, action) => {
        state.CTotalloading = true;
        state.error = "";
      })
      .addCase(CloudUserTotal.fulfilled, (state, action) => {
        state.CTotalData = action.payload;
        state.CTotalloading = false;
      })
      .addCase(CloudUserTotal.rejected, (state, action) => {
        state.CTotalloading = false;
        state.CTotalData = [];
        state.error = "something went wrong";

      })
      //CPending
      .addCase(CloudUserPending.pending, (state, action) => {
        state.CTotalloading = true;
        state.error = "";
      })
      .addCase(CloudUserPending.fulfilled, (state, action) => {
        state.CPendingData = action.payload;
        state.CTotalloading = false;
      })
      .addCase(CloudUserPending.rejected, (state, action) => {
        state.CTotalloading = false;
        state.CPendingData = [];
        state.error = "something went wrong";

      })
      //CContinue
      .addCase(CloudUserContinue.pending, (state, action) => {
        state.CTotalloading = true;
        state.error = "";
      })
      .addCase(CloudUserContinue.fulfilled, (state, action) => {
        state.CContinueData = action.payload;
        state.CTotalloading = false;
      })
      .addCase(CloudUserContinue.rejected, (state, action) => {
        state.CTotalloading = false;
        state.CContinueData = [];
        state.error = "something went wrong";

      })

      //CRemove
      .addCase(CloudUserRemove.pending, (state, action) => {
        state.CTotalloading = true;
        state.error = "";
      })
      .addCase(CloudUserRemove.fulfilled, (state, action) => {
        state.CRemovedData = action.payload;
        state.CTotalloading = false;
      })
      .addCase(CloudUserRemove.rejected, (state, action) => {
        state.CTotalloading = false;
        state.CRemovedData = [];
        state.error = "something went wrong";

      })
      //PTotal
      .addCase(onpremiseTotal.pending, (state, action) => {
        state.CTotalloading = true;
        state.error = "";
      })
      .addCase(onpremiseTotal.fulfilled, (state, action) => {
        state.PTotalData = action.payload;
        state.CTotalloading = false;
      })
      .addCase(onpremiseTotal.rejected, (state, action) => {
        state.CTotalloading = false;
        state.PTotalData = [];
        state.error = "something went wrong";

      })
      //PPending
      .addCase(onpremisePending.pending, (state, action) => {
        state.CTotalloading = true;
        state.error = "";
      })
      .addCase(onpremisePending.fulfilled, (state, action) => {
        state.PPendingData = action.payload;
        state.CTotalloading = false;
      })
      .addCase(onpremisePending.rejected, (state, action) => {
        state.CTotalloading = false;
        state.PPendingData = [];
        state.error = "something went wrong";

      })
      //PContinue
      .addCase(onpremiseContinue.pending, (state, action) => {
        state.CTotalloading = true;
        state.error = "";
      })
      .addCase(onpremiseContinue.fulfilled, (state, action) => {
        state.PContinueData = action.payload;
        state.CTotalloading = false;
      })
      .addCase(onpremiseContinue.rejected, (state, action) => {
        state.CTotalloading = false;
        state.PContinueData = [];
        state.error = "something went wrong";

      })
      //PRemove
      .addCase(onpremiseRemove.pending, (state, action) => {
        state.CTotalloading = true;
        state.error = "";
      })
      .addCase(onpremiseRemove.fulfilled, (state, action) => {
        state.PRemovedData = action.payload;
        state.CTotalloading = false;
      })
      .addCase(onpremiseRemove.rejected, (state, action) => {
        state.CTotalloading = false;
        state.PRemovedData = [];
        state.error = "something went wrong";

      })
      //COMContinue
      .addCase(CompletedCContinue.pending, (state, action) => {
        state.CompletedLoading = true;
        state.error = "";
      })
      .addCase(CompletedCContinue.fulfilled, (state, action) => {
        state.CompltedCContinueData = action.payload;
        state.CompletedLoading = false;
      })
      .addCase(CompletedCContinue.rejected, (state, action) => {
        state.CompletedLoading = false;
        state.CompltedCContinueData = [];
        state.error = "something went wrong";

      })
      //COMemove
      .addCase(CompletedCRemove.pending, (state, action) => {
        state.CompletedLoading = true;
        state.error = "";
      })
      .addCase(CompletedCRemove.fulfilled, (state, action) => {
        state.CompltedCRemoveData = action.payload;
        state.CompletedLoading = false;
      })
      .addCase(CompletedCRemove.rejected, (state, action) => {
        state.CompletedLoading = false;
        state.CompltedCRemoveData = [];
        state.error = "something went wrong";

      })

      //COMPerContinue
      .addCase(CompletedPermiseContinue.pending, (state, action) => {
        state.CompletedLoading = true;
        state.error = "";
      })
      .addCase(CompletedPermiseContinue.fulfilled, (state, action) => {
        state.CompltedPContinueData = action.payload;
        state.CompletedLoading = false;
      })
      .addCase(CompletedPermiseContinue.rejected, (state, action) => {
        state.CompletedLoading = false;
        state.CompltedPContinueData = [];
        state.error = "something went wrong";

      })
      //COMPerRemove
      .addCase(CompletedPermiseRemove.pending, (state, action) => {
        state.CompletedLoading = true;
        state.error = "";
      })
      .addCase(CompletedPermiseRemove.fulfilled, (state, action) => {
        state.CompltedPRemoveData = action.payload;
        state.CompletedLoading = false;
      })
      .addCase(CompletedPermiseRemove.rejected, (state, action) => {
        state.CompletedLoading = false;
        state.CompltedPRemoveData = [];
        state.error = "something went wrong";

      })
      //ComplianceTrendAPI
      .addCase(ComplianceTrendAPI.pending, (state, action) => {
        state.complianceLoading = true;
      })
      .addCase(ComplianceTrendAPI.fulfilled, (state, action) => {
        state.complianceGraphData = action.payload;
        state.complianceLoading = false;
      })
      .addCase(ComplianceTrendAPI.rejected, (state, action) => {
        state.complianceLoading = false;
      })
  },
});

export const { reviewSlicereset } = reviewslice.actions;
export default reviewslice.reducer;