import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../app/utilities/helpers";

const cookies = new Cookies();

export const userManagementListAPI = createAsyncThunk(
  "post/userManagementListAPI",
  async (props) => {
    console.log(props);
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageLimit, pageNo } = props;
      const response = await fetch(
        `/api/v1/licence/user/licenceUserListForSearchWithPagination/${pageNo}/${pageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const userManagementCountAPI = createAsyncThunk(
  "post/userManagementCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNo, pageLimit } = props;
      const response = await fetch(
        `/api/v1/licence/user/licenceUserCountForSearchWithPagination`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const configurationSlice = createSlice({
  name: "configuration",
  initialState: {
    toolsPlugin: [],
    toolName: [],
    userManagementLoadPage: false,
    userManagementListData: [],
    userManagementListDataLoading: false,
    userManagementListCount: [],
    userManagementListCountLoading: false,
  },
  reducers: {
    setToolsPlugin: {
      reducer(state, action) {
        state.toolsPlugin = action.payload;
      },
    },
    setToolsName: {
      reducer(state, action) {
        state.toolName = action.payload;
      },
    },
    setUserManagement: {
      reducer(state, action) {
        state.userManagementLoadPage = action.payload;
      },
    },
  },

  extraReducers: (builder) => {
    builder
      //userManagementListAPI
      .addCase(userManagementListAPI.pending, (state, action) => {
        state.userManagementListDataLoading = true;
      })
      .addCase(userManagementListAPI.fulfilled, (state, action) => {
        state.userManagementListDataLoading = false;
        state.userManagementListData = action.payload;
      })
      .addCase(userManagementListAPI.rejected, (state, action) => {
        state.userManagementListDataLoading = false;
      })

      //userManagementCountAPI
      .addCase(userManagementCountAPI.pending, (state, action) => {
        state.userManagementListCountLoading = true;
      })
      .addCase(userManagementCountAPI.fulfilled, (state, action) => {
        state.userManagementListCountLoading = false;
        state.userManagementListCount = action.payload;
      })
      .addCase(userManagementCountAPI.rejected, (state, action) => {
        state.userManagementListCountLoading = false;
      })
  }
});

export const { setToolsPlugin, setToolsName, setUserManagement } = configurationSlice.actions;

export default configurationSlice.reducer;
