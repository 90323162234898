import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { propTypes } from 'react-bootstrap/esm/Image';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../app/utilities/helpers';

const cookies = new Cookies();

export const budgetedVsActualCostTrend = createAsyncThunk(
    "post/budgetedVsActualCostTrend",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getCostTreadGraph`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const costTrendApplication = createAsyncThunk(
    "post/costTrendApplication",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getCostTrendByApplication`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const costTrendAccountId = createAsyncThunk(
    "post/costTrendAccountId",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getCostTrendByAccountId`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const costTrendResources = createAsyncThunk(
    "post/costTrendResources",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getTopFiveServiceByAccountId`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const costTrendRegion = createAsyncThunk(
    "post/costTrendRegion",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getTopFiveRegionByAccountId`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const cloudCost = createAsyncThunk(
    "post/cloudCost",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`/api/v1/awsCloudCost/getCloudCostListSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const cloudCostDrill = createAsyncThunk(
    "posts/getDrill",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getCloudCostByAccountIdSearchPaginationDrillDown1/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });


export const cloudCostDrillDown = createAsyncThunk(
    "posts/getDrilldown",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { accountId, pageNumber, itemsPerPage, payload, appCodes } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getRegionCostCountSortingAndPaginationListDrillDownTwo/${appCodes}/${accountId}/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });
export const cloudCostPagenation = createAsyncThunk(
    "posts/getTablePage",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getCloudCostListSearchAndPaginationCount`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });
export const cloudCostDrillPagenation = createAsyncThunk(
    "posts/getDrillCountPage",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getCloudCostByAccountIdSearchPaginationCountDrillDown1`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const cloudCostRegionCount = createAsyncThunk(
    "posts/getDrillRegionCountPage",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { accountId, payload } = props;
            const response = await fetch(`/api/v1/awsCloudCost/getCloudCostByAccountIdSearchPaginationCountDrillDown2/${accountId}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });
export const getFilteredCSP = createAsyncThunk(
    "posts/getFilteredCSP",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { CSPpayload } = props;
            const payload = CSPpayload;
            const response = await fetch(`/api/v1/CSPForAws/appCodes`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

const initialState = {
    cloudTableData: [],
    cloudData: [],
    cloudDataDrill: [],
    costTrendRegionData: [],
    costTrendResourcesData: [],
    costTrendAccountIdData: [],
    costTrendApplicationData: [],
    budgetedVsActualCostTrendData: [],
    cloudCostTablePage: [],
    cloudCostDrillPageCount: [],
    cloudCostDrillRegionCount: [],
    CSPFilterData: [],
    loading: false,
    DrillLoading: false,
    regionLoading: false,
    tableCountloading: false,
    drillCountLoading: false,
    regionCountLoading: false,
    accLoading: false,
    appLoading: false,
    bvaLoading: false,
    resLoading: false,
    regLoading: false,
    filterLoading: false,
    error: ""
};

export const CostSlice = createSlice({
    name: 'cloudCostSlice',
    initialState,


    reducers: {
        resetCostSliceData: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(budgetedVsActualCostTrend.pending, (state, action) => {
                state.bvaLoading = true;
            })
            .addCase(budgetedVsActualCostTrend.fulfilled, (state, action) => {
                state.budgetedVsActualCostTrendData = action.payload;
                state.bvaLoading = false;
            })
            .addCase(budgetedVsActualCostTrend.rejected, (state, action) => {
                state.bvaLoading = false;
                state.budgetedVsActualCostTrendData = [];
                state.error = "Something went wrong";
            })

            .addCase(costTrendApplication.pending, (state, action) => {
                state.appLoading = true;
            })
            .addCase(costTrendApplication.fulfilled, (state, action) => {
                state.costTrendApplicationData = action.payload;
                state.appLoading = false;
            })
            .addCase(costTrendApplication.rejected, (state, action) => {
                state.appLoading = false;
                state.costTrendApplicationData = [];
                state.error = "Something went wrong";

            })

            .addCase(costTrendAccountId.pending, (state, action) => {
                state.accLoading = true;
            })
            .addCase(costTrendAccountId.fulfilled, (state, action) => {
                state.costTrendAccountIdData = action.payload;
                state.accLoading = false;
            })
            .addCase(costTrendAccountId.rejected, (state, action) => {
                state.accLoading = false;
                state.costTrendAccountIdData = [];
                state.error = "Something went wrong";
            })

            .addCase(costTrendResources.pending, (state, action) => {
                state.resLoading = true;
            })
            .addCase(costTrendResources.fulfilled, (state, action) => {
                state.costTrendResourcesData = action.payload;
                state.resLoading = false;
            })
            .addCase(costTrendResources.rejected, (state, action) => {
                state.resLoading = false;
                state.costTrendResourcesData = [];
                state.error = "Something went wrong";

            })

            .addCase(costTrendRegion.pending, (state, action) => {
                state.regLoading = true;
            })
            .addCase(costTrendRegion.fulfilled, (state, action) => {
                state.costTrendRegionData = action.payload;
                state.regLoading = false;
            })
            .addCase(costTrendRegion.rejected, (state, action) => {
                state.regLoading = false;
                state.costTrendRegionData = [];
                state.error = "Something went wrong";
            })

            .addCase(cloudCost.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(cloudCost.fulfilled, (state, action) => {
                state.cloudTableData = action.payload;
                state.loading = false;
            })
            .addCase(cloudCost.rejected, (state, action) => {
                state.loading = false;
                state.cloudTableData = [];
                state.error = "Something went wrong";
            })


            .addCase(cloudCostDrill.pending, (state, action) => {
                state.DrillLoading = true;
            })
            .addCase(cloudCostDrill.fulfilled, (state, action) => {
                state.cloudData = action.payload;
                state.DrillLoading = false;
            })
            .addCase(cloudCostDrill.rejected, (state, action) => {
                state.DrillLoading = false;
                state.cloudData = [];
                state.error = "Something went wrong";
            })


            .addCase(cloudCostDrillDown.pending, (state, action) => {
                state.regionLoading = true;
            })
            .addCase(cloudCostDrillDown.fulfilled, (state, action) => {
                state.cloudDataDrill = action.payload;
                state.regionLoading = false;
            })
            .addCase(cloudCostDrillDown.rejected, (state, action) => {
                state.regionLoading = false;
                state.cloudDataDrill = [];
                state.error = "Something went wrong";
            })


            .addCase(cloudCostPagenation.pending, (state, action) => {
                state.tableCountloading = true;
            })
            .addCase(cloudCostPagenation.fulfilled, (state, action) => {
                state.cloudCostTablePage = action.payload;
                state.tableCountloading = false;
            })
            .addCase(cloudCostPagenation.rejected, (state, action) => {
                state.tableCountloading = false;
                state.cloudCostTablePage = [];
                state.error = "Something went wrong";
            })


            .addCase(cloudCostDrillPagenation.pending, (state, action) => {
                state.drillCountLoading = true;
            })
            .addCase(cloudCostDrillPagenation.fulfilled, (state, action) => {
                state.cloudCostDrillPageCount = action.payload;
                state.drillCountLoading = false;
            })
            .addCase(cloudCostDrillPagenation.rejected, (state, action) => {
                state.drillCountLoading = false;
                state.cloudCostDrillPageCount = [];
                state.error = "Something went wrong";
            })


            .addCase(cloudCostRegionCount.pending, (state, action) => {
                state.regionCountLoading = true;
            })
            .addCase(cloudCostRegionCount.fulfilled, (state, action) => {
                state.cloudCostDrillRegionCount = action.payload;
                state.regionCountLoading = false;

            })
            .addCase(cloudCostRegionCount.rejected, (state, action) => {
                state.regionCountLoading = false;
                state.cloudCostDrillRegionCount = [];
                state.error = "Something went wrong";


            })

            .addCase(getFilteredCSP.pending, (state, action) => {
                state.filterLoading = true;
            })
            .addCase(getFilteredCSP.fulfilled, (state, action) => {
                state.CSPFilterData = action.payload;
                state.filterLoading = false;

            })
            .addCase(getFilteredCSP.rejected, (state, action) => {
                state.filterLoading = false;
                state.CSPFilterData = [];
                state.error = "Something went wrong";
            });
    },

});

export const { resetCostSliceData } = CostSlice.actions;
export default CostSlice.reducer;