import React, { useEffect } from 'react'
import { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { setSessionExtend, setTimeoutId, setsessionToken } from './app/commonSlice';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { resetLoggedUser, setPageLoading, setLoggedUser } from "./app/commonSlice";
import { resetHomeData } from './Home/HomeSlice';
import Cookies from 'universal-cookie';

const IdleTimercontainer = () => {
  const idleTimeRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const onIdle = () => {

    if (location.pathname !== "/auth") {
      dispatch(setSessionExtend(true));

      const id = setTimeout(() => {

        setTimeout(() => {
          dispatch(setSessionExtend(false));

          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          fetch("/logout", {
            method: "GET",
            headers: {
              "DTOP_API_TOKEN": Apitoken,
              "USERNAME": User,
            },
            redirect: "manual",
          })
            .then(() => {
              return fetch("/invalidateToken", {
                method: "GET",
                headers: {
                  "DTOP_API_TOKEN": Apitoken,
                  "USERNAME": User,
                },
                redirect: "manual",
              });
            })
            .then(() => {
              handleLogout();
              navigate("/auth");
              dispatch(resetLoggedUser());
              dispatch(resetHomeData());
              dispatch(setsessionToken(false))
            })
            .catch((err) => {
              console.error(err);
            });
        }, 1000);

      }, 60 * 1000);

      dispatch(setTimeoutId(id));
    }
  };
  const handleLogout = () => {
    cookies.set("apitoken", "");
    cookies.set("username", "");
    cookies.set("referstoken", "");
    dispatch(setLoggedUser({ userName: "", userRole: "", token: "" }));
    dispatch(setPageLoading(true));
    navigate("/auth");
  };

  useIdleTimer({
    crossTab: true,
    ref: idleTimeRef,
    timeout: 29 * 60 * 1000,
    onIdle: onIdle,
  });

  useEffect(() => {
    let apiToken = cookies.get("apitoken");
    if (!apiToken) {
      navigate("/auth");
    }
  }, [cookies.get("apitoken")])

  return (
    <div>

    </div>
  )
}

export default IdleTimercontainer

