import React from "react";
import { pathNames } from "../utilities/constants";
import { routes } from "../../app/routes";
import { useNavigate } from "react-router";
import _ from "lodash";
import { useSelector } from "react-redux";
const BreadCrumb = (props) => {
    const navigate = useNavigate();
    const topicState = useSelector((state) => state.common?.complianceAssessmentStates?.topic);
    const subTopicState = useSelector((state) => state.common?.complianceAssessmentStates?.subtopic);
    const clauseState = useSelector((state) => state.common?.complianceAssessmentStates?.clause);
    const questionState = useSelector((state) => state.common?.complianceAssessmentStates?.questions);

    let currentPath = "";
    const getPathName = (name, isLastIndex) => {
        if (typeof pathNames[name] !== "undefined") {
            let returnVal = (pathNames[name] && !isLastIndex) ? `${pathNames[name]}` : pathNames[name];
            return returnVal;
        } else {

            let nameArr = decodeURIComponent(name).split(" ");
            const names = _.map(nameArr, item => {
                return item.replaceAll("/", " ");
            });

            return `${names.join(" ")}`;

        }

    }
    const getPathArray = () => {
        const pathArr = props.path.split("/");

        return _.filter(pathArr, path => path.length > 0);
    }
    const generateUrl = (parentObj, pathArr) => {
        if (_.has(parentObj, "childNavs")) {
            _.map(parentObj.childNavs, childNav => {
                generateUrl(childNav, pathArr);
            })
        } else {
            const currentUrl = pathArr.join("/");
            if (currentUrl == parentObj.path) {
                currentPath = currentUrl;
            }
        }
        return currentPath;
    }
    const getUrl = (pathArr, index) => {
        currentPath = "";
        const parentPath = _.find(routes, { path: pathArr[0] });
        const newPathArr = pathArr.slice(0, index + 1);
        const generatedUrl = generateUrl(parentPath, newPathArr);
        return (generatedUrl && index < pathArr.length - 1) ? currentPath : "";
    }
    const pathArray = getPathArray();

    const pushStates = (url) => {
        const pageName = url.split("/").pop();
        console.log("Pathname", pageName);
        if (pageName === "Topics") {
            return { ...topicState };
        }
        if (pageName === "SubTopics") {
            return { ...subTopicState };
        }
        if (pageName === "Clause") {
            return { ...clauseState };
        }
        if (pageName === "Questions") {
            return { ...questionState };
        }
    };

    return (
        <div>
            {
                _.map(pathArray, (item, index) => {
                    if (item) {
                        const isLastIndex = index == getPathArray().length - 1;
                        let pathName = getPathName(item, isLastIndex);
                        let url = getUrl(pathArray, index);

                        return <h3 className="breadCrumb">
                            {url ? <a className="breadCrumbItem" onClick={() => { navigate(url, pushStates(url)) }}>{pathName}</a> : <span>{pathName}</span>}
                            {index < pathArray.length - 1 && <span>{typeof (pathNames[item]) == "undefined" ? " - " : (pathNames[item].length == 0 ? "" : " > ")}</span>}
                            {/* <Breadcrumb.Item className="breadCrumbItem" href={url}>{pathName}</Breadcrumb.Item> */}
                        </h3>
                    }
                })
            }
        </div >
    )
}

export default BreadCrumb;