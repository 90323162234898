import React from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";

const cookies = new Cookies();

//PolicyNameList
export const PolicyNameListApi = createAsyncThunk(
  "post/PolicyNameListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/complianceAssessment/getPolicyNameList`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          }
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//Topic,SubTopic,AssessmentCount
export const TopicSubTopicAndAssessmentCountAPI = createAsyncThunk(
  "post/getTopicSubTopicAndAssessmentCountAPI",
  async (props) => {
    const { policyName } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/complianceAssessment/getTopicSubTopicAndAssessmentCount/${policyName}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          }
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//createComplianceAssessment
export const CreateAssessmentAPI = createAsyncThunk(
  "post/CreateAssessmentAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/createComplianceAssessment`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });
//ComplianceAssessmentDelete
export const ComplianceAssessmentDeleteAPI = createAsyncThunk(
  "post/ComplianceAssessmentDeleteAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, userName } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/getComplianceAssessmentForDelete/${userName}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);
//saveDataComplianceAssessmentQuestionDetails
export const saveDataComplianceAssessmentQuestionDetails = createAsyncThunk(
  "post/saveDataComplianceAssessmentQuestionDetails",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/saveDataComplianceAssessmentQuestionDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const TriggerMailToAoForReview = createAsyncThunk(
  "post/submitForAoReviewMail",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch("/api/v1/complianceAssessment/submitForAoReviewMail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username
          },
          body: JSON.stringify(Payload)
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const TriggerMailToCisoForReview = createAsyncThunk(
  "post/submitForCisoReviewMail",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch("/api/v1/complianceAssessment/submitForCisoReviewMail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username
          },
          body: JSON.stringify(Payload)
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const userSubmitForReview = createAsyncThunk(
  "post/submitForUserReview",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/submitForUserReview`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const recheckAoReview = createAsyncThunk(
  "post/recheckAoReview",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/recheckAoReview`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const recheckUserReview = createAsyncThunk(
  "post/recheckUserReview",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/recheckUserReview`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const aoSubmitForApproval = createAsyncThunk(
  "post/submitForApprovalAo",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/submitForApprovalAo`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const cisoSubmitForCompletion = createAsyncThunk(
  "post/completeAssessmentCiso",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/completeAssessmentCiso`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const deleteAttachment = createAsyncThunk(
  "post/deleteAttachments",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/deleteAttachments/${username}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const saveAttachments = createAsyncThunk(
  "post/saveAttachments",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { files, Id } = props;
      const formData = new FormData();
      files.forEach(file => {
        formData.append('file', file);
      });
      const response = await fetch(
        `/api/v1/complianceAssessment/complianceAssessmentSaveQuestionId/${Id}`,
        {
          method: "POST",
          headers: {
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
            "Content-Type": "multipart/form-data"
          },
          body: formData,
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const downloadAttachments = createAsyncThunk(
  "post/complianceAssessmentSaveQuestionId",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { fileName, Id } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/downloadAttachmentQuestion/${Id}/${fileName}`,
        {
          method: "GET",
          headers: {
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const blob = await response.blob();

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      return { blobUrl, fileName };
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

export const getAllListFromComplianceAssessment = createAsyncThunk(
  "post/getAllListFromComplianceAssessment",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/getAllListFromComplianceAssessment`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });
//Download
export const DownloadList = createAsyncThunk(
  "post/DownloadList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/generatePdf`,
        {
          method: "POST",
          headers: {
            Accept: "application/octet-stream",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.blob();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getAllListFromComplianceAssessmentCount = createAsyncThunk(
  "post/getAllListFromComplianceAssessmentCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `/api/v1/complianceAssessment/getAllListFromSubTopicAndTopicCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

const ComplianceAssessment = createSlice({
  name: "ComplianceAssessment",
  initialState: {
    PolicyLoading: false,
    PolicyList: [],
    TopicSubTopicAndAssessmentCountLoading: false,
    TopicSubTopicAndAssessmentCountData: [],
    CreateAssessmentLoading: false,
    CreateAssessmentData: [],
    ComplianceAssessmentDeleteLoading: false,
    ComplianceAssessmentDeleteData: {},
    errorDelete: "",
    DownloadListloading: false,
    DownloadListDetails: [],
    submitLoading: false,
    submitActionResponse: {},
    submitLoading: false,
    attachmentLoading: false,
    attachmentData: [],
    downloadAttachmentLoading: false,
    attachmentPreview: [],
    questionsLoading: true,
    questionsListFromComplianceAssessment: [],
    questionListFromComplianceAssessmentCount: []

  },

  extraReducers: (builder) => {
    builder
      //PolicyNameList
      .addCase(PolicyNameListApi.pending, (state, action) => {
        state.PolicyLoading = true;
      })
      .addCase(PolicyNameListApi.fulfilled, (state, action) => {
        state.PolicyList = action.payload;
        state.PolicyLoading = false;
      })
      .addCase(PolicyNameListApi.rejected, (state, action) => {
        state.PolicyLoading = false;
        state.PolicyList = [];
      })

      //Topic,SubTopic,AssessmentCount
      .addCase(TopicSubTopicAndAssessmentCountAPI.pending, (state, action) => {
        state.TopicSubTopicAndAssessmentCountLoading = true;
      })
      .addCase(TopicSubTopicAndAssessmentCountAPI.fulfilled, (state, action) => {
        state.TopicSubTopicAndAssessmentCountData = action.payload;
        state.TopicSubTopicAndAssessmentCountLoading = false;
      })
      .addCase(TopicSubTopicAndAssessmentCountAPI.rejected, (state, action) => {
        state.TopicSubTopicAndAssessmentCountLoading = false;
        state.TopicSubTopicAndAssessmentCountData = [];
      })
      //CreateAssessment
      .addCase(CreateAssessmentAPI.pending, (state, action) => {
        state.CreateAssessmentLoading = true;
      })
      .addCase(CreateAssessmentAPI.fulfilled, (state, action) => {
        state.CreateAssessmentData = action.payload;
        state.CreateAssessmentLoading = false;
      })
      .addCase(CreateAssessmentAPI.rejected, (state, action) => {
        state.CreateAssessmentLoading = false;
        state.CreateAssessmentData = [];
      })
      //trigger mail to AO for review
      .addCase(TriggerMailToAoForReview.pending, (state, action) => {
      })
      .addCase(TriggerMailToAoForReview.fulfilled, (state, action) => {
      })
      .addCase(TriggerMailToAoForReview.rejected, (state, action) => {
      })

      //trigger mail to CISO for review
      .addCase(TriggerMailToCisoForReview.pending, (state, action) => {
      })
      .addCase(TriggerMailToCisoForReview.fulfilled, (state, action) => {
      })
      .addCase(TriggerMailToCisoForReview.rejected, (state, action) => {
      })
      //submit for review
      .addCase(userSubmitForReview.pending, (state, action) => {
        state.submitLoading = true;
      })
      .addCase(userSubmitForReview.fulfilled, (state, action) => {
        state.submitActionResponse = action.payload;
        state.submitLoading = false;
      })
      .addCase(userSubmitForReview.rejected, (state, action) => {
        state.submitLoading = false;
        state.submitActionResponse = action.payload;
      })
      //recheckAoReview
      .addCase(recheckAoReview.pending, (state, action) => {
        state.submitLoading = true;
      })
      .addCase(recheckAoReview.fulfilled, (state, action) => {
        state.submitActionResponse = action.payload;
        state.submitLoading = false;
      })
      .addCase(recheckAoReview.rejected, (state, action) => {
        state.submitLoading = false;
        state.submitActionResponse = action.payload;
      })
      //recheckUserReview
      .addCase(recheckUserReview.pending, (state, action) => {
        state.submitLoading = true;
      })
      .addCase(recheckUserReview.fulfilled, (state, action) => {
        state.submitActionResponse = action.payload;
        state.submitLoading = false;
      })
      .addCase(recheckUserReview.rejected, (state, action) => {
        state.submitLoading = false;
        state.submitActionResponse = action.payload;
      })
      //ao submit for approval
      .addCase(aoSubmitForApproval.pending, (state, action) => {
        state.submitLoading = true;
      })
      .addCase(aoSubmitForApproval.fulfilled, (state, action) => {
        state.submitActionResponse = action.payload;
        state.submitLoading = false;
      })
      .addCase(aoSubmitForApproval.rejected, (state, action) => {
        state.submitLoading = false;
        state.submitActionResponse = action.payload;
      })
      //ciso submit for approval
      .addCase(cisoSubmitForCompletion.pending, (state, action) => {
        state.submitLoading = true;
      })
      .addCase(cisoSubmitForCompletion.fulfilled, (state, action) => {
        state.submitActionResponse = action.payload;
        state.submitLoading = false;
      })
      .addCase(cisoSubmitForCompletion.rejected, (state, action) => {
        state.submitLoading = false;
        state.submitActionResponse = action.payload;
      })
      //ComplianceAssessmentDelete
      .addCase(ComplianceAssessmentDeleteAPI.pending, (state, action) => {
        state.ComplianceAssessmentDeleteLoading = true;
      })
      .addCase(ComplianceAssessmentDeleteAPI.fulfilled, (state, action) => {
        state.ComplianceAssessmentDeleteData = action.payload;
        state.ComplianceAssessmentDeleteLoading = false;
      })
      .addCase(ComplianceAssessmentDeleteAPI.rejected, (state, action) => {
        state.ComplianceAssessmentDeleteLoading = false;
        state.errorDelete = "Something went wrong";
      })
      //saveDataComplianceAssessmentQuestionDetails
      .addCase(saveDataComplianceAssessmentQuestionDetails.pending, (state, action) => {
        state.saveDataLoading = true;
      })
      .addCase(saveDataComplianceAssessmentQuestionDetails.fulfilled, (state, action) => {
        state.saveData = action.payload;
        state.saveDataLoading = false;
      })
      .addCase(saveDataComplianceAssessmentQuestionDetails.rejected, (state, action) => {
        state.saveDataLoading = false;
        state.saveData = [];
      })
      //ListFromComplianceAssessment
      .addCase(getAllListFromComplianceAssessment.pending, (state, action) => {
        state.questionsLoading = true
      })
      .addCase(getAllListFromComplianceAssessment.fulfilled, (state, action) => {
        state.questionsListFromComplianceAssessment = action.payload;
        state.questionsLoading = false;
      })
      .addCase(getAllListFromComplianceAssessment.rejected, (state, action) => {
        state.questionsLoading = false;
        state.questionsListFromComplianceAssessment = [];
      })
      .addCase(getAllListFromComplianceAssessmentCount.pending, (state, action) => {

      })
      .addCase(getAllListFromComplianceAssessmentCount.fulfilled, (state, action) => {
        state.questionListFromComplianceAssessmentCount = action.payload;
      })
      .addCase(getAllListFromComplianceAssessmentCount.rejected, (state, action) => {
        state.questionListFromComplianceAssessmentCount = 0;
      })
      //saveAttachments
      .addCase(saveAttachments.pending, (state, action) => {
        state.attachmentLoading = true
      })
      .addCase(saveAttachments.fulfilled, (state, action) => {
        state.attachmentData = action.payload;
        state.attachmentLoading = false;
      })
      .addCase(saveAttachments.rejected, (state, action) => {
        state.attachmentLoading = false;
      })
      //Download
      .addCase(DownloadList.pending, (state, action) => {
        state.DownloadListloading = true;
      })
      .addCase(DownloadList.fulfilled, (state, action) => {
        state.DownloadListDetails = action.payload;
        state.DownloadListloading = false;
      })
      .addCase(DownloadList.rejected, (state, action) => {
        state.DownloadListloading = false;
      })
      //
      .addCase(downloadAttachments.pending, (state, action) => {
        state.downloadAttachmentLoading = true
      })
      .addCase(downloadAttachments.fulfilled, (state, action) => {
        state.downloadAttachmentLoading = false;
        state.attachmentPreview = action.payload.blobUrl;
      })
      .addCase(downloadAttachments.rejected, (state, action) => {
        state.downloadAttachmentLoading = false;
      })


  }
})

export const { } =
  ComplianceAssessment.actions;
export default ComplianceAssessment.reducer;