import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";
import { post } from "jquery";
import { handleResponseCode } from "../../../app/utilities/helpers";

const cookies = new Cookies();


export const OpenSBOMchartAPI = createAsyncThunk(
  "post/OpenSBOMchartAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/sbomController/getOpenFindingsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const DistributionSBOMAPI = createAsyncThunk(
  "post/DistributionSBOMAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/sbomController/findingsDistribution`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const ClosedSBOMchartAPI = createAsyncThunk(
  "post/ClosedSBOMchartAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/sbomController/getClosedFindingsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const ActionbyplanSBOMAPI = createAsyncThunk(
  "post/ActionbyplanSBOMAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/sbomController/getStatusBySeverity`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const AppCriticalitySBOMAPI = createAsyncThunk(
  "post/AppCriticalitySBOMAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/sbomController/getOpenCountByCriticalityFiltered`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const TargetDateMissedSBOMAPI = createAsyncThunk(
  "post/TargetDateMissedSBOMAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `	/api/v1/sbomController/getTargetDates`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const TargetDateDueTrendSBOMAPI = createAsyncThunk(
  "post/TargetDateDueTrendSBOMAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `	/api/v1/sbomController/getSixMonthTargetDate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);






export const SBOMTableListAPI = createAsyncThunk(
  "post/SBOMTableListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/sbomController/getSBOMVulnerabilityList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const SBOMTableCountAPI = createAsyncThunk(
  "post/SBOMTableCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/sbomController/getSBOMVulnerabilityListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const SBOMTableManageAPI = createAsyncThunk(
  "post/SBOMTableManageAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      console.log(payload)
      const response = await fetch(
        `/api/v1/sbomController/vulnerability/manageVulnerability`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const SBOMFixedTableListAPI = createAsyncThunk(
  "post/SBOMFixedTableListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/sbomController/getSBOMFixedVulnerabilityList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const SBOMFixedTableCountAPI = createAsyncThunk(
  "post/SBOMFixedTableCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/sbomController/getSBOMFixedVulnerabilityListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vulnerabilityIdDetails = createAsyncThunk(
  "post/vulnerabilityIdDetails",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const vulnerabilityId = props;
      const response = await fetch(
        `/api/v1/sbomController/list/vulnerabilityIdDetails/${vulnerabilityId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const DetailsSBOMApi = createAsyncThunk("post/DetailsSBOMApi", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  const { id } = props;
  try {
    const response = await fetch(` /api/v1/sbomController/commentDescription/list/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        DTOP_API_TOKEN: apiToken,
        USERNAME: username,
      },
      // body: JSON.stringify(payload),
    });
    handleResponseCode(response);
    const data = await response.json();

    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});


export const BasescorematricsSBOMApi = createAsyncThunk("get/BasescorematricsSBOMApi", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  const { id } = props;
  try {
    const response = await fetch(` /api/v1/sbomController/getBaseScoreMetricsDetails/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        DTOP_API_TOKEN: apiToken,
        USERNAME: username,
      },
      // body: JSON.stringify(payload),
    });
    handleResponseCode(response);
    const data = await response.json();

    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});






const initialState = {

  OpenSBOMData: [],
  OpenSBOMDataLoading: false,
  DistributionSBOMData: [],
  DistributionSBOMDataLoading: false,
  closedSBOMData: [],
  closedSBOMDataLoading: false,
  ActionbyplanSBOMData: [],
  ActionbyplanSBOMDataLoading: false,
  AppCriticalitySBOMData: [],
  AppCriticalitySBOMDataLoading: false,
  TargetDateMissedSBOMData: [],
  TargetDateMissedSBOMDataLoading: false,
  TargetDateDueTrendSBOMData: [],
  TargetDateDueTrendSBOMDataLoading: false,
  SBOMTableListData: [],
  SBOMTableListLoading: false,
  SBOMTableCountData: [],
  SBOMTableCountLoading: false,
  SBOMTableManageData: [],
  SBOMTableManageLoading: false,
  SBOMFixedTableListData: [],
  SBOMFixedTableListLoading: false,
  SBOMFixedTableCountData: [],
  SBOMFixedTableCountLoading: false,
  disabledCategories: [],
  vulnerabilityIdDetailsData: [],
  vulnerabilityIdDetailsLoading: false,
  DetailsData: [],
  DetailsLoading: false,
  BasescoreData: [],
  BasescoreLoading: false
};
export const SBOMSlice = createSlice({
  name: "SBOMSlice",
  initialState,

  reducers: {
    resetSBOMData: () => {
      return initialState;
    },
    setDisabledCategories: (state, action) => {
      state.disabledCategories = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      //OpenSBOM
      .addCase(OpenSBOMchartAPI.pending, (state, action) => {
        state.OpenSBOMDataLoading = true;
      })
      .addCase(OpenSBOMchartAPI.fulfilled, (state, action) => {
        state.OpenSBOMData = action.payload;
        state.OpenSBOMDataLoading = false;
      })
      .addCase(OpenSBOMchartAPI.rejected, (state, action) => {
        state.OpenSBOMData = [];
        state.OpenSBOMDataLoading = false;
      })
      //DistributionSBOM
      .addCase(DistributionSBOMAPI.pending, (state, action) => {
        state.DistributionSBOMDataLoading = true;
      })
      .addCase(DistributionSBOMAPI.fulfilled, (state, action) => {
        state.DistributionSBOMData = action.payload;
        state.DistributionSBOMDataLoading = false;
      })
      .addCase(DistributionSBOMAPI.rejected, (state, action) => {
        state.DistributionSBOMData = [];
        state.DistributionSBOMDataLoading = false;
      })

      //ClosedSBOMchart
      .addCase(ClosedSBOMchartAPI.pending, (state, action) => {
        state.closedSBOMDataLoading = true;
      })
      .addCase(ClosedSBOMchartAPI.fulfilled, (state, action) => {
        state.closedSBOMData = action.payload;
        state.closedSBOMDataLoading = false;
      })
      .addCase(ClosedSBOMchartAPI.rejected, (state, action) => {
        state.closedSBOMData = [];
        state.closedSBOMDataLoading = false;
      })

      //ActionbyplanSBOM
      .addCase(ActionbyplanSBOMAPI.pending, (state, action) => {
        state.ActionbyplanSBOMDataLoading = true;
      })
      .addCase(ActionbyplanSBOMAPI.fulfilled, (state, action) => {
        state.ActionbyplanSBOMData = action.payload;
        state.ActionbyplanSBOMDataLoading = false;
      })
      .addCase(ActionbyplanSBOMAPI.rejected, (state, action) => {
        state.ActionbyplanSBOMData = [];
        state.ActionbyplanSBOMDataLoading = false;
      })
      //App Criticality
      .addCase(AppCriticalitySBOMAPI.pending, (state, action) => {
        state.AppCriticalitySBOMDataLoading = true;
      })
      .addCase(AppCriticalitySBOMAPI.fulfilled, (state, action) => {
        state.AppCriticalitySBOMData = action.payload;
        state.AppCriticalitySBOMDataLoading = false;
      })
      .addCase(AppCriticalitySBOMAPI.rejected, (state, action) => {
        state.AppCriticalitySBOMData = [];
        state.AppCriticalitySBOMDataLoading = false;
      })
      //Target Date Missed
      .addCase(TargetDateMissedSBOMAPI.pending, (state, action) => {
        state.TargetDateMissedSBOMDataLoading = true;
      })
      .addCase(TargetDateMissedSBOMAPI.fulfilled, (state, action) => {
        state.TargetDateMissedSBOMData = action.payload;
        state.TargetDateMissedSBOMDataLoading = false;
      })
      .addCase(TargetDateMissedSBOMAPI.rejected, (state, action) => {
        state.TargetDateMissedSBOMData = [];
        state.TargetDateMissedSBOMDataLoading = false;
      })

      //Target Date Due Trend
      .addCase(TargetDateDueTrendSBOMAPI.pending, (state, action) => {
        state.TargetDateDueTrendSBOMDataLoading = true;
      })
      .addCase(TargetDateDueTrendSBOMAPI.fulfilled, (state, action) => {
        state.TargetDateDueTrendSBOMData = action.payload;
        state.TargetDateDueTrendSBOMDataLoading = false;
      })
      .addCase(TargetDateDueTrendSBOMAPI.rejected, (state, action) => {
        state.TargetDateDueTrendSBOMData = [];
        state.TargetDateDueTrendSBOMDataLoading = false;
      })

      //SBOMTableListAPI
      .addCase(SBOMTableListAPI.pending, (state, action) => {
        state.SBOMTableListLoading = true,
          state.error = "";
      })
      .addCase(SBOMTableListAPI.fulfilled, (state, action) => {
        state.SBOMTableListData = action.payload;
        state.SBOMTableListLoading = false;
      })
      .addCase(SBOMTableListAPI.rejected, (state, action) => {
        state.SBOMTableListLoading = false;
        state.SBOMTableListData = [];
        state.error = "something went wrong";
      })

      //SBOMTableCountAPI
      .addCase(SBOMTableCountAPI.pending, (state, action) => {
        state.SBOMTableCountLoading = true;
        state.error = "";
      })
      .addCase(SBOMTableCountAPI.fulfilled, (state, action) => {
        state.SBOMTableCountData = action.payload;
        state.SBOMTableCountLoading = false;
      })
      .addCase(SBOMTableCountAPI.rejected, (state, action) => {
        state.SBOMTableCountLoading = false;
        state.SBOMTableCountData = [];
        state.error = "something went wrong";
      })
      //SBOMTableManageAPI
      .addCase(SBOMTableManageAPI.pending, (state, action) => {
        state.SBOMTableManageLoading = true;
        state.error = "";
      })
      .addCase(SBOMTableManageAPI.fulfilled, (state, action) => {
        state.SBOMTableManageData = action.payload;
        state.SBOMTableManageLoading = false;
      })
      .addCase(SBOMTableManageAPI.rejected, (state, action) => {
        state.SBOMTableManageLoading = false;
        state.SBOMTableManageData = [];
        state.error = "something went wrong";
      })

      //SBOMFixedTableListAPI
      .addCase(SBOMFixedTableListAPI.pending, (state, action) => {
        state.SBOMFixedTableListLoading = true,
          state.error = "";
      })
      .addCase(SBOMFixedTableListAPI.fulfilled, (state, action) => {
        state.SBOMFixedTableListData = action.payload;
        state.SBOMFixedTableListLoading = false;
      })
      .addCase(SBOMFixedTableListAPI.rejected, (state, action) => {
        state.SBOMFixedTableListLoading = false;
        state.SBOMFixedTableListData = [];
        state.error = "something went wrong";
      })
      //SBOMFixedTableCountAPI
      .addCase(SBOMFixedTableCountAPI.pending, (state, action) => {
        state.SBOMFixedTableCountLoading = true;
        state.error = "";
      })
      .addCase(SBOMFixedTableCountAPI.fulfilled, (state, action) => {
        state.SBOMFixedTableCountData = action.payload;
        state.SBOMFixedTableCountLoading = false;
      })
      .addCase(SBOMFixedTableCountAPI.rejected, (state, action) => {
        state.SBOMFixedTableCountLoading = false;
        state.SBOMFixedTableCountData = [];
        state.error = "something went wrong";
      })
      //vulnerabilityIdDetails
      .addCase(vulnerabilityIdDetails.pending, (state, action) => {
        state.vulnerabilityIdDetailsLoading = true;
        state.error = "";
      })
      .addCase(vulnerabilityIdDetails.fulfilled, (state, action) => {
        state.vulnerabilityIdDetailsData = action.payload;
        state.vulnerabilityIdDetailsLoading = false;
      })
      .addCase(vulnerabilityIdDetails.rejected, (state, action) => {
        state.vulnerabilityIdDetailsLoading = false;
        state.vulnerabilityIdDetailsData = [];
        state.error = "something went wrong";
      })

      //Details
      .addCase(DetailsSBOMApi.pending, (state, action) => {
        state.DetailsLoading = true;
        state.error = "";
      })
      .addCase(DetailsSBOMApi.fulfilled, (state, action) => {
        state.DetailsData = action.payload;
        state.DetailsLoading = false;
      })
      .addCase(DetailsSBOMApi.rejected, (state, action) => {
        state.DetailsData = []
        state.DetailsLoading = false;;
        state.error = "something went wrong";
      })

      //Basescorematrics
      .addCase(BasescorematricsSBOMApi.pending, (state, action) => {
        state.BasescoreLoading = true;
        state.error = "";
      })
      .addCase(BasescorematricsSBOMApi.fulfilled, (state, action) => {
        state.BasescoreData = action.payload;
        state.BasescoreLoading = false;
      })
      .addCase(BasescorematricsSBOMApi.rejected, (state, action) => {
        state.BasescoreData = []
        state.BasescoreLoading = false;;
        state.error = "something went wrong";
      })


  },
});
export const { resetSBOMData, setDisabledCategories } = SBOMSlice.actions;
export default SBOMSlice.reducer;